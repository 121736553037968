import React, { useState, useEffect, useCallback } from 'react'
import {
	Button,
	TextField,
	Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Payment from '../../components/Payment'

const useStyles = makeStyles({
	container: {
		borderStyle: 'solid',
		borderColor: '#5840BB',
		borderWidth: '2em',
		textAlign: 'left',
		padding: '40px',
		background: 'white',
		height: '70vh'
	},
	saveUserBtn: {
		float: 'left'
	}
});

const questionary = {
	description: 'Full PREDICT',
	amount: 5.99,
	currency: "EUR"
}

export default function PayPageFullPredict() {
	const classes = useStyles()
	const [language, setLanguage] = useState(null)
	const [codes, setCodes] = useState(null)
	const [error, setError] = useState(null)

	const [userBody, setUserBody] = useState({
		firstName: '',
		lastName: '',
		email: '',
	})

	const [responseCreateUser, setResponsetReponseCreateUser] = useState(null)
	const [selectedTab, setSelectedTab] = useState(0)

	useEffect(() => {
		if (localStorage.getItem('language_JSON')) setLanguage(JSON.parse(localStorage.getItem('language_JSON')))
	}, [])

	useEffect(() => {
		if (!responseCreateUser) return
		if (responseCreateUser._id) {
			setSelectedTab(1)
		}
	}, [responseCreateUser])

	const onCreateUser = useCallback(() => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(userBody)
		}
		fetch(window.baseURL + '/users/', requestOptions)
			.then(response => response.json())
			.then(body => setResponsetReponseCreateUser(body))
	}, [userBody])

	const onBuyQuestionaire = useCallback(async (questionaryID, orderId, payerId) => {
		if (!responseCreateUser?._id) return
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				questionaryId: questionaryID,
				userId: responseCreateUser._id,
				orderId,
				payerId
			})
		}
		const fetchResponse = await fetch(window.baseURL + '/questionary/buy', requestOptions)
			.then(response => response.json())
		if (!fetchResponse?.code) return {}
		localStorage.setItem(`${questionaryID}`, fetchResponse.code)
		return fetchResponse
	}, [responseCreateUser])

	const onBuyManySurveys = async ({ orderId, payerId }) => {
		Promise.all([
			onBuyQuestionaire(orderId, payerId, 'chestionar1'),
			onBuyQuestionaire(orderId, payerId, 'chestionar2'),
			onBuyQuestionaire(orderId, payerId, 'chestionar3'),
			onBuyQuestionaire(orderId, payerId, 'chestionar4')
		]).then((values) => {
			if (!values[0]?.code || !values[1]?.code || !values[2]?.code || !values[3]?.code) setError(true)
			const tempCodes = []
			for (const value of values) {
				tempCodes.push({
					label: value.payerId,
					code: value.code
				})
			}
			sendEmail(tempCodes)
			setCodes(tempCodes)
		});
	}

	const sendEmail = useCallback((codes) => {
		if (codes.length !== 4) return

		localStorage.setItem('chestionar1', codes[0].code)
		localStorage.setItem('chestionar2', codes[1].code)
		localStorage.setItem('chestionar3', codes[2].code)
		localStorage.setItem('chestionar4', codes[3].code)

		let bodyHTML = ` <div>
                                <br />
                                <span>
								${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b>${language.homePage.questionaryCards.calamnitati.title}</b>: <b>${codes[0].code}</b>
								</span>
                                <br />
                                <br />
                                <span>
								${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b>${language.homePage.questionaryCards.riscRazboi.title}</b>: <b>${codes[1].code}</b>
								</span>
                                <br />
                                <br />
                                <span>
								${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b>${language.homePage.questionaryCards.pandemie.title}</b>: <b>${codes[2].code}</b>
								</span>
                                <br />
                                <br />
                                <span>
								${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b>${language.homePage.questionaryCards.crizaFinanciara.title}</b>: <b>${codes[3].code}</b>
								</span>
                                <br />
                                <br />
                            </div>`
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: responseCreateUser?._id,
				subject: 'Confirmare plata FULL PREDICT',
				message: bodyHTML
			})
		}
		fetch(window.baseURL + '/email/send', requestOptions)
			.then(response => response.json())
	}, [responseCreateUser])

	return (
		language ? (<div className={classes.container}>
			{selectedTab === 0 ?
				(
					<div>
						<h1>{language.homePage.analizeModal.title}</h1>
						<br ></br>
						<h3>
							{language.homePage.analizeModal.fullPredictBuyContent}
						</h3>
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='text'
							label={language?.homePage?.analizeModal?.firstName}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, firstName: e?.target?.value })}
						/>
						<br />
						<br />
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='text'
							label={language?.homePage?.analizeModal?.lastName}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, lastName: e?.target?.value })}
						/>
						<br />
						<br />
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='text'
							label={language?.homePage?.analizeModal?.email}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, email: e?.target?.value })}
						/>
						<br />
						<br />

						<Button
							disabled={!userBody.firstName || !userBody.lastName || !userBody.email}
							className={classes.saveUserBtn}
							variant='contained'
							color='primary'
							onClick={() => onCreateUser()}
						>
							{language.homePage.analizeModal.send}
						</Button>
					</div>
				) : null}
			{selectedTab === 1 ?
				(
					<div>
						<Payment questionary={questionary} onSuccess={onBuyManySurveys} />
						{codes ? (
							<div>
								<h3>
									{language.homePage.analizeModal.fullPredictInstructions}
								</h3>
								<br />
								<br />
								{codes.map(code => (
									<>
										<span>{language.homePage.analizeModal.codeFor} <b>{code?.label}</b>: <b>{code?.code}</b></span>
										<br />
										<br />
									</>
								))}
							</div>
						) : null}
						{error ? (
							<Alert severity="error">{language.homePage.analizeModal.error}</Alert>
						) : null}
						<br />
						<br />
						{error || codes?.length ? (<Button
							variant='contained'
							color='primary'
							onClick={() => window.location.href = window.location.origin}
						>
							{language.homePage.analizeModal.goToMenu}
						</Button>) : null}
					</div>
				) : null}
		</div>) : null
	)
}
