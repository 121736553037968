import './App.css';
// Components
import Home from './pages/home/index'
import PayFullPredict from './pages/home/pay-full-predict'
import FullPredictResult from './pages/home/full-predict-result'
import SurveyOne from './pages/surveys/survey-one/index'
import SurveyOnePay from './pages/surveys/survey-one/pay-page'
import SurveyTwo from './pages/surveys/survey-two/index'
import SurveyTwoPay from './pages/surveys/survey-two/pay-page'
import SurveyThree from './pages/surveys/survey-three/index'
import SurveyThreePay from './pages/surveys/survey-three/pay-page'
import SurveyFour from './pages/surveys/survey-four/index'
import SurveyFourPay from './pages/surveys/survey-four/pay-page'
import Login from './pages/admin-area/login/index'
import Statistics from './pages/admin-area/statistics/index'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id": "AZP1K5y7bD_50_Wya6CcvRpcFcl6WB6z51Is8nsFNJQlFKdu5uXYhKG7EdFY49H9ufHTdtEKiwsEZVNd",  // -prod
  // "client-id": "AfOF7FNLucljl9_eT94cV_rpmjlEjo0x_QuRiaAp7CIeOIvW9TRjUwSPUG5rrzbVlp6C7WvUAZoKsohY",  // - dev
  currency: 'EUR'
};


function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <div className="App">
          <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/pay-full-predict" exact element={<PayFullPredict />} />
          <Route path="/full-predict-result" exact element={<FullPredictResult />} />
          <Route path="/one" element={<SurveyOne />} />
          <Route path="/one-pay" element={<SurveyOnePay />} />
          <Route path="/two" element={<SurveyTwo />} />
          <Route path="/two-pay" element={<SurveyTwoPay />} />
          <Route path="/three" element={<SurveyThree />} />
          <Route path="/three-pay" element={<SurveyThreePay />} />
          <Route path="/four" element={<SurveyFour />} />
          <Route path="/four-pay" element={<SurveyFourPay />} />
          <Route path="/login" element={<Login />} />
          <Route path="/statistics" element={<Statistics />} />
          </Routes>
        </div>
      </BrowserRouter>
    </PayPalScriptProvider>
  )
}

export default App;
