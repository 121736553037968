import React, { useState, useEffect, useCallback } from 'react'
import {
    Button,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Alert,
    TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        borderStyle: 'solid',
        borderColor: '#5840BB',
        borderWidth: '2em',
        textAlign: 'left',
        padding: '40px',
        background: 'white'
    },
    surveyContainer: {
        overflowX: 'auto',
        maxHeight: '70vh',
        background: 'white'
    }
});

const survey = [
    {
        tab: 0,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 1, selected: false },
                    { index: 1, value: 2, selected: false },
                    { index: 2, value: 3, selected: false },
                    { index: 3, value: 4, selected: false },
                    { index: 4, value: 5, selected: false },
                    { index: 5, value: 6, selected: false },
                ]
            }
        ]
    },
    {
        tab: 1,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 1, selected: false },
                    { index: 1, value: 2, selected: false },
                    { index: 2, value: 3, selected: false },
                    { index: 3, value: 4, selected: false },
                    { index: 4, value: 6, selected: false },
                    { index: 5, value: 8, selected: false },
                ]
            },
        ]
    },
    {
        tab: 2,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0.2, selected: false },
                    { index: 1, value: 0.3, selected: false },
                    { index: 2, value: 0.5, selected: false },
                    { index: 3, value: 0.7, selected: false },
                    { index: 4, value: 0.8, selected: false },
                    { index: 5, value: 0.9, selected: false },
                    { index: 6, value: 1, selected: false },
                ]
            },
        ]
    },
    {
        tab: 3,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 13, selected: false },
                    { index: 1, value: 9, selected: false },
                    { index: 2, value: 8, selected: false },
                    { index: 3, value: 14, selected: false },
                    { index: 4, value: 14, selected: false },
                    { index: 5, value: 8, selected: false },
                    { index: 6, value: 14, selected: false },
                    { index: 7, value: 14, selected: false },
                    { index: 8, value: 14, selected: false },
                    { index: 9, value: 14, selected: false },
                    //
                    { index: 10, value: 18, selected: false },
                    { index: 11, value: 14, selected: false },
                    { index: 12, value: 13, selected: false },
                    { index: 13, value: 14, selected: false },
                    { index: 14, value: 18, selected: false },
                    { index: 15, value: 14, selected: false },
                    { index: 16, value: 7, selected: false },
                    { index: 17, value: 10, selected: false },
                    { index: 18, value: 13, selected: false },
                    { index: 19, value: 10, selected: false },
                    { index: 20, value: 7, selected: false },
                    //
                    { index: 21, value: 18, selected: false },
                    { index: 22, value: 14, selected: false },
                    { index: 23, value: 16, selected: false },
                    { index: 24, value: 12, selected: false },
                    { index: 25, value: 14, selected: false },
                    { index: 26, value: 18, selected: false },
                    { index: 27, value: 15, selected: false },
                    { index: 28, value: 8, selected: false },
                    { index: 29, value: 8, selected: false },
                    //
                    { index: 30, value: 18, selected: false },
                    { index: 31, value: 15, selected: false },
                    { index: 32, value: 12, selected: false },
                    { index: 33, value: 13, selected: false },
                    { index: 34, value: 15, selected: false },
                    { index: 35, value: 13, selected: false },
                    { index: 36, value: 17, selected: false },
                    { index: 37, value: 16, selected: false },
                    { index: 38, value: 12, selected: false },
                    //
                    { index: 39, value: 12, selected: false },
                    { index: 40, value: 12, selected: false },
                    { index: 41, value: 6, selected: false },
                    { index: 42, value: 6, selected: false },
                    { index: 43, value: 8, selected: false },
                    { index: 44, value: 5, selected: false },
                    { index: 45, value: 5, selected: false },
                    //
                    { index: 46, value: 10, selected: false },
                    { index: 47, value: 10, selected: false },
                    //
                    { index: 48, value: 9, selected: false },
                    { index: 49, value: 10, selected: false },
                    { index: 50, value: 8, selected: false },
                    { index: 51, value: 8, selected: false },
                    //
                    { index: 52, value: 4, selected: false },
                    { index: 53, value: 3, selected: false },
                    { index: 54, value: 10, selected: false },
                    //
                    { index: 55, value: 16, selected: false },
                    { index: 56, value: 14, selected: false },
                    { index: 57, value: 9, selected: false },
                    { index: 58, value: 2, selected: false },
                    { index: 59, value: 4, selected: false },
                    //
                    { index: 60, value: 2, selected: false },
                    { index: 61, value: 7, selected: false },
                    { index: 62, value: 7, selected: false },
                    { index: 63, value: 2, selected: false },
                    { index: 64, value: 5, selected: false },
                    { index: 65, value: 4, selected: false },
                    { index: 66, value: 9, selected: false },
                    //
                    { index: 67, value: 9, selected: false },
                    { index: 68, value: 2, selected: false },
                    { index: 69, value: 2, selected: false },
                    //
                    { index: 70, value: 9, selected: false },
                    { index: 71, value: 15, selected: false },
                    { index: 72, value: 16, selected: false },
                    { index: 73, value: 12, selected: false },
                    { index: 74, value: 8, selected: false },
                    { index: 75, value: 3, selected: false },
                    { index: 76, value: 13, selected: false },
                    { index: 77, value: 3, selected: false },
                    //
                    { index: 78, value: 5, selected: false },
                    { index: 79, value: 2, selected: false },
                    { index: 80, value: 3, selected: false },
                    { index: 81, value: 2, selected: false },
                    { index: 82, value: 3, selected: false },
                    { index: 83, value: 3, selected: false },
                    { index: 84, value: 2, selected: false },
                    //
                    { index: 85, value: 10, selected: false },
                    { index: 86, value: 10, selected: false },
                    { index: 87, value: 8, selected: false },
                    { index: 88, value: 4, selected: false },
                    { index: 89, value: 4, selected: false },
                    { index: 90, value: 4, selected: false },
                    //
                    { index: 91, value: 8, selected: false },
                    //
                    { index: 92, value: 8, selected: false },
                    { index: 93, value: 7, selected: false },
                    { index: 94, value: 5, selected: false },
                    { index: 95, value: 8, selected: false },
                    //
                    { index: 96, value: 8, selected: false },
                    { index: 97, value: 8, selected: false },
                    { index: 98, value: 6, selected: false },
                    { index: 99, value: 10, selected: false },
                    { index: 100, value: 14, selected: false },
                    { index: 101, value: 14, selected: false },
                    { index: 102, value: 16, selected: false },
                    { index: 103, value: 14, selected: false },
                    //
                    { index: 104, value: 7, selected: false },
                    { index: 105, value: 4, selected: false },
                    { index: 106, value: 5, selected: false },
                    { index: 107, value: 8, selected: false },
                    { index: 108, value: 4, selected: false },
                    //
                    { index: 109, value: 1, selected: false },
                    { index: 110, value: 2, selected: false },
                    { index: 111, value: 2, selected: false },
                    { index: 112, value: 1, selected: false },
                    //
                    { index: 113, value: 8, selected: false },
                    { index: 114, value: 14, selected: false },
                    { index: 115, value: 12, selected: false },
                ]
            },
        ]
    },
    {
        tab: 4,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0, selected: false },
                    { index: 1, value: 4, selected: false },
                    { index: 2, value: 16, selected: false },
                    { index: 3, value: 20, selected: false },
                    { index: 4, value: 24, selected: false },
                ]
            },
        ]
    },
    {
        tab: 5,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0.1, selected: false },
                    { index: 1, value: 0.2, selected: false },
                    { index: 2, value: 0.6, selected: false },
                    { index: 3, value: 0.8, selected: false },
                    { index: 4, value: 1, selected: false },
                ]
            },
        ]
    },
    {
        tab: 6,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0, selected: false },
                    { index: 1, value: 3, selected: false },
                ]
            },
        ]
    },
    {
        tab: 7,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0, selected: false },
                    { index: 1, value: 3, selected: false },
                ]
            },
        ]
    },
    {
        tab: 8,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0, selected: false },
                    { index: 1, value: 4, selected: false },
                    { index: 2, value: 12, selected: false },
                    { index: 3, value: 15, selected: false },
                    { index: 4, value: 18, selected: false },
                    { index: 5, value: 22, selected: false },
                    { index: 6, value: 23, selected: false },
                ]
            },
        ]
    },
    {
        tab: 9,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 7, selected: false },
                    { index: 1, value: 5, selected: false },
                    { index: 2, value: 3, selected: false },
                    { index: 3, value: 0, selected: false },
                ]
            },
        ]
    },
    {
        tab: 10,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0.2, selected: false },
                    { index: 1, value: 0.4, selected: false },
                    { index: 2, value: 0.5, selected: false },
                    { index: 3, value: 0.7, selected: false },
                    { index: 4, value: 1, selected: false },
                ]
            },
        ]
    },
    {
        tab: 11,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 0.3, selected: false },
                    { index: 1, value: 0.8, selected: false },
                    { index: 2, value: 1.2, selected: false },
                    { index: 3, value: 2, selected: false },
                ]
            },
        ]
    },
    {
        tab: 12,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 2, selected: false },
                    { index: 1, value: 1, selected: false },
                    { index: 2, value: 1, selected: false },
                    { index: 3, value: 2, selected: false },
                    { index: 4, value: 2, selected: false },
                    { index: 5, value: 0, selected: false },
                    { index: 6, value: 2, selected: false },
                ]
            },
        ]
    },

]

export default function SurveyTwo() {
    const classes = useStyles()

    const [language, setLanguage] = useState(null)
    const [surveyCode, setSuveyCode] = useState(null)
    const [surveyState, setSurveyState] = useState(survey)
    const [currentTab, setSelectedTab] = useState(0)
    const [error, setError] = useState(false)
    const [errorCode, setErrorCode] = useState(false)
    const [total, setTotal] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('language_JSON')) setLanguage(JSON.parse(localStorage.getItem('language_JSON')))
        if (!localStorage.getItem('chestionar2')) return
        setSuveyCode(localStorage.getItem('chestionar2'))
    }, [])

    const goToNextTab = useCallback(() => {
        if (!checkIfQuestionIsCompleted(surveyState[currentTab])) {
            setError(true)
            return
        } else setError(false)

        setSelectedTab(currentTab + 1)
    }, [currentTab, surveyState])

    const checkIfQuestionIsCompleted = (tab) => {
        let isCompleted = false
        if (tab.questions.length !== 1) {

            let haveAnyQuestionUncomplete = false
            for (let question of tab.questions) {
                if (question.answers.filter(answer => answer.selected).length === 0) {
                    haveAnyQuestionUncomplete = true
                }
            }
            isCompleted = !haveAnyQuestionUncomplete
        } else {
            for (let question of tab.questions) {
                for (let answer of question.answers) {
                    if (answer.selected) {
                        isCompleted = true
                    }
                }
            }
        }

        return isCompleted
    }

    const goToPreviousTab = useCallback(() => {
        setSelectedTab(currentTab - 1)
    }, [currentTab])

    const handleRadioChange = (event) => {
        if (!event.target.value) return
        const indexOfTab = event.target.value.split('-')[0]
        const indexOfQuestion = event.target.value.split('-')[1]
        const indexOfAnswer = event.target.value.split('-')[2]


        const tempSurveyState = [...surveyState]

        for (let answer of tempSurveyState[indexOfTab].questions[indexOfQuestion].answers) {
            if (answer.index !== +indexOfAnswer) {
                answer.selected = false
            } else answer.selected = true
        }

        setSurveyState(tempSurveyState)
    };

    const onCountTotal = useCallback(() => {
        if (!checkIfQuestionIsCompleted(surveyState[5])) {
            setError(true)
            return
        } else setError(false)

        let conter = 0
        for (let tab of surveyState) {
            for (let question of tab.questions) {
                for (let answer of question.answers) {
                    if (answer.selected) conter += answer.value
                }
            }
        }
        localStorage.setItem('chestionar-2-result', +conter.toFixed(2))
        setSelectedTab(currentTab + 1)
        setTotal(conter)
        consumeTheCode(surveyCode, +conter)
    }, [surveyState, surveyCode])

    const onValidateSurveyCode = useCallback(() => {
        if (!surveyCode) {
            setErrorCode(true)
            return
        }
        const requestOptions = {
            method: 'POST',
            body: new URLSearchParams({ code: surveyCode })
        };
        fetch(window.baseURL + '/questionary/check', requestOptions)
            .then(response => response.json())
            .then(body => {
                if (body.result === true) {
                    setErrorCode(false)
                    setSelectedTab(0)
                } else setErrorCode(true)
            })
    }, [surveyCode])

    const consumeTheCode = (code, counter) => {
        if (localStorage.getItem('chestionar2')) {
            localStorage.removeItem('chestionar2')
        }

        let bodyHTML = ` <div>
        <h1>Rezultat:</h1>

        <h3>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart1} ${counter.toFixed(2)} ${language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart2}!</h3>

        <h1>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.rewardTitle}: ${showResponse(counter)}</h1>

        <h1>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandationTitle}:</h1>

        <h4>${showRecomandation(counter)}</h4>
        </div>`

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                answers: [],
                subject: 'Rezultat chestionar',
                message: bodyHTML,
                results: {
                    label: showResponse(counter),
                    value: counter
                }
            })
        }
        fetch(window.baseURL + '/questionary/complete', requestOptions)
        .then(response => response.json())
    }

    const showResponse = (total) => {
        if (total > 0 && total <= 30) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward1
        }
        if (total > 30 && total <= 50) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward2
        }
        if (total > 50 && total <= 75) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward3
        }
        if (total > 75 && total <= 100) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward4
        }
    }

    const showRecomandation = (total) => {
        if (total > 0 && total <= 30) {
            return (
                <div>
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation1}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r11}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r12}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r13}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r14}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r15}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r16}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r1C}</p>
                </div>
            )

        }
        if (total > 30 && total <= 50) {
            return (
                <div>
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation2}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r21}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r22}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r23}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r24}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r25}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r2c}</p>
                </div>
            )
        }
        if (total > 50 && total <= 75) {
            return (
                <div>
                    <h3>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation3}</h3>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r31}</p>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r32}</p>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r33}</p>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r34}</p>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r35}</p>
                    <p>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.r3c}</p>
                </div >
            )
        }
        if (total > 75 && total <= 100) {
            return (
                < div >
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation4}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r41}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r42}</p>
                </div >
            )
        }
    }

    if (!language) return

    return (
        <div className={classes.container}>
            <div className={classes.surveyContainer}>
                {currentTab === -1 ? (
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabOnePresentation.title}</h1>
                        <h4>
                            <div>{language.questionaryRazboi.questionaryPage.tabOnePresentation.criterii}</div>
                            <br />
                            <div>{language.questionaryRazboi.questionaryPage.tabOnePresentation.question}</div>
                            <br />
                            <div>{language.questionaryRazboi.questionaryPage.tabOnePresentation.answer}</div>
                            <br />
                        </h4>
                        <label>{language.questionaryRazboi.questionaryPage.tabOnePresentation.pleaseAddCode}</label>
                        <br />
                        <TextField
                            style={{ maxWidth: 400 }}
							fullWidth
                            id="outlined-basic"
                            label={null}
                            variant="outlined"
                            value={surveyCode}
                            onChange={(e) => setSuveyCode(e?.target?.value)}
                        />
                        <br />
                        <br />
                        {errorCode ? (<Alert severity="error">Incorrect code</Alert>) : null}
                        <br />
                        <Button
                            className={classes.saveUserBtn}
                            variant='contained'
                            color='primary'
                            onClick={() => onValidateSurveyCode()}
                        >
                            Înainte
                        </Button>
                    </div>
                ) : null}
                {/*  Tab 1 */}
                {currentTab === 0 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabTwo.question}</h1>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="firstQuestion"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='0-0-0' control={<Radio checked={surveyState[0].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer1} />
                                <FormControlLabel value='0-0-1' control={<Radio checked={surveyState[0].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer2} />
                                <FormControlLabel value='0-0-2' control={<Radio checked={surveyState[0].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer3} />
                                <FormControlLabel value='0-0-3' control={<Radio checked={surveyState[0].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer4} />
                                <FormControlLabel value='0-0-4' control={<Radio checked={surveyState[0].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer5} />
                                <FormControlLabel value='0-0-5' control={<Radio checked={surveyState[0].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwo.answer6} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 2 */}
                {currentTab === 1 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabThree.question}</h1>

                        <h3>{language.questionaryRazboi.questionaryPage.tabThree.domain1}</h3>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="secondQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='1-0-0' control={<Radio checked={surveyState[1].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer1} />
                                <FormControlLabel value='1-0-1' control={<Radio checked={surveyState[1].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer2} />
                                <FormControlLabel value='1-0-2' control={<Radio checked={surveyState[1].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer3} />
                                <FormControlLabel value='1-0-3' control={<Radio checked={surveyState[1].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer4} />
                                <FormControlLabel value='1-0-4' control={<Radio checked={surveyState[1].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer5} />
                                <FormControlLabel value='1-0-5' control={<Radio checked={surveyState[1].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabThree.answer6} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 3 */}
                {currentTab === 2 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabFour.question}</h1>

                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="thirdQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='2-0-0' control={<Radio checked={surveyState[2].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer1} />
                                <FormControlLabel value='2-0-1' control={<Radio checked={surveyState[2].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer2} />
                                <FormControlLabel value='2-0-2' control={<Radio checked={surveyState[2].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer3} />
                                <FormControlLabel value='2-0-3' control={<Radio checked={surveyState[2].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer4} />
                                <FormControlLabel value='2-0-4' control={<Radio checked={surveyState[2].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer5} />
                                <FormControlLabel value='2-0-5' control={<Radio checked={surveyState[2].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer6} />
                                <FormControlLabel value='2-0-6' control={<Radio checked={surveyState[2].questions[0].answers[6].selected} />} label={language.questionaryRazboi.questionaryPage.tabFour.answer7} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 4 */}
                {currentTab === 3 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabFive.question}</h1>
                        <h5>{language.questionaryRazboi.questionaryPage.tabFive.description}</h5>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="FourthQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.question}</h3>
                                <FormControlLabel value='3-0-0' control={<Radio checked={surveyState[3].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer1} />
                                <FormControlLabel value='3-0-1' control={<Radio checked={surveyState[3].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer2} />
                                <FormControlLabel value='3-0-2' control={<Radio checked={surveyState[3].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer3} />
                                <FormControlLabel value='3-0-3' control={<Radio checked={surveyState[3].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer4} />
                                <FormControlLabel value='3-0-4' control={<Radio checked={surveyState[3].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer5} />
                                <FormControlLabel value='3-0-5' control={<Radio checked={surveyState[3].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer6} />
                                <FormControlLabel value='3-0-6' control={<Radio checked={surveyState[3].questions[0].answers[6].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer7} />
                                <FormControlLabel value='3-0-7' control={<Radio checked={surveyState[3].questions[0].answers[7].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer8} />
                                <FormControlLabel value='3-0-8' control={<Radio checked={surveyState[3].questions[0].answers[8].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer9} />
                                <FormControlLabel value='3-0-9' control={<Radio checked={surveyState[3].questions[0].answers[9].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer10} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain2}</h3>
                                <FormControlLabel value='3-0-10' control={<Radio checked={surveyState[3].questions[0].answers[10].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer11} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain3}</h3>
                                <FormControlLabel value='3-0-11' control={<Radio checked={surveyState[3].questions[0].answers[11].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer12} />
                                <FormControlLabel value='3-0-12' control={<Radio checked={surveyState[3].questions[0].answers[12].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer13} />
                                <FormControlLabel value='3-0-13' control={<Radio checked={surveyState[3].questions[0].answers[13].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer14} />
                                <FormControlLabel value='3-0-14' control={<Radio checked={surveyState[3].questions[0].answers[14].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer15} />
                                <FormControlLabel value='3-0-15' control={<Radio checked={surveyState[3].questions[0].answers[15].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer16} />
                                <FormControlLabel value='3-0-16' control={<Radio checked={surveyState[3].questions[0].answers[16].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer17} />
                                <FormControlLabel value='3-0-17' control={<Radio checked={surveyState[3].questions[0].answers[17].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer18} />
                                <FormControlLabel value='3-0-18' control={<Radio checked={surveyState[3].questions[0].answers[18].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer19} />
                                <FormControlLabel value='3-0-19' control={<Radio checked={surveyState[3].questions[0].answers[19].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer20} />
                                <FormControlLabel value='3-0-20' control={<Radio checked={surveyState[3].questions[0].answers[20].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer21} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain4}</h3>
                                <FormControlLabel value='3-0-21' control={<Radio checked={surveyState[3].questions[0].answers[21].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer22} />
                                <FormControlLabel value='3-0-22' control={<Radio checked={surveyState[3].questions[0].answers[22].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer23} />
                                <FormControlLabel value='3-0-23' control={<Radio checked={surveyState[3].questions[0].answers[23].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer24} />
                                <FormControlLabel value='3-0-24' control={<Radio checked={surveyState[3].questions[0].answers[24].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer25} />
                                <FormControlLabel value='3-0-25' control={<Radio checked={surveyState[3].questions[0].answers[25].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer26} />
                                <FormControlLabel value='3-0-26' control={<Radio checked={surveyState[3].questions[0].answers[26].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer27} />
                                <FormControlLabel value='3-0-27' control={<Radio checked={surveyState[3].questions[0].answers[27].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer28} />
                                <FormControlLabel value='3-0-28' control={<Radio checked={surveyState[3].questions[0].answers[28].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer29} />
                                <FormControlLabel value='3-0-29' control={<Radio checked={surveyState[3].questions[0].answers[29].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer30} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain5}</h3>
                                <FormControlLabel value='3-0-30' control={<Radio checked={surveyState[3].questions[0].answers[30].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer31} />
                                <FormControlLabel value='3-0-31' control={<Radio checked={surveyState[3].questions[0].answers[31].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer32} />
                                <FormControlLabel value='3-0-32' control={<Radio checked={surveyState[3].questions[0].answers[32].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer33} />
                                <FormControlLabel value='3-0-33' control={<Radio checked={surveyState[3].questions[0].answers[33].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer34} />
                                <FormControlLabel value='3-0-34' control={<Radio checked={surveyState[3].questions[0].answers[34].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer35} />
                                <FormControlLabel value='3-0-35' control={<Radio checked={surveyState[3].questions[0].answers[35].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer36} />
                                <FormControlLabel value='3-0-36' control={<Radio checked={surveyState[3].questions[0].answers[36].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer37} />
                                <FormControlLabel value='3-0-37' control={<Radio checked={surveyState[3].questions[0].answers[37].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer38} />
                                <FormControlLabel value='3-0-38' control={<Radio checked={surveyState[3].questions[0].answers[38].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer39} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain6}</h3>
                                <FormControlLabel value='3-0-39' control={<Radio checked={surveyState[3].questions[0].answers[39].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer40} />
                                <FormControlLabel value='3-0-40' control={<Radio checked={surveyState[3].questions[0].answers[40].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer41} />
                                <FormControlLabel value='3-0-41' control={<Radio checked={surveyState[3].questions[0].answers[41].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer42} />
                                <FormControlLabel value='3-0-42' control={<Radio checked={surveyState[3].questions[0].answers[42].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer43} />
                                <FormControlLabel value='3-0-43' control={<Radio checked={surveyState[3].questions[0].answers[43].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer44} />
                                <FormControlLabel value='3-0-44' control={<Radio checked={surveyState[3].questions[0].answers[44].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer45} />
                                <FormControlLabel value='3-0-45' control={<Radio checked={surveyState[3].questions[0].answers[45].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer46} />
                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain7}</h3>
                                <FormControlLabel value='3-0-46' control={<Radio checked={surveyState[3].questions[0].answers[46].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer47} />
                                <FormControlLabel value='3-0-47' control={<Radio checked={surveyState[3].questions[0].answers[47].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer48} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain8}</h3>
                                <FormControlLabel value='3-0-48' control={<Radio checked={surveyState[3].questions[0].answers[48].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer49} />
                                <FormControlLabel value='3-0-49' control={<Radio checked={surveyState[3].questions[0].answers[49].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer50} />
                                <FormControlLabel value='3-0-50' control={<Radio checked={surveyState[3].questions[0].answers[50].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer51} />
                                <FormControlLabel value='3-0-51' control={<Radio checked={surveyState[3].questions[0].answers[51].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer52} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain9}</h3>
                                <FormControlLabel value='3-0-52' control={<Radio checked={surveyState[3].questions[0].answers[52].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer53} />
                                <FormControlLabel value='3-0-53' control={<Radio checked={surveyState[3].questions[0].answers[53].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer54} />
                                <FormControlLabel value='3-0-54' control={<Radio checked={surveyState[3].questions[0].answers[54].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer55} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain10}</h3>
                                <FormControlLabel value='3-0-55' control={<Radio checked={surveyState[3].questions[0].answers[55].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer56} />
                                <FormControlLabel value='3-0-56' control={<Radio checked={surveyState[3].questions[0].answers[56].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer57} />
                                <FormControlLabel value='3-0-57' control={<Radio checked={surveyState[3].questions[0].answers[57].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer58} />
                                <FormControlLabel value='3-0-58' control={<Radio checked={surveyState[3].questions[0].answers[58].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer59} />
                                <FormControlLabel value='3-0-59' control={<Radio checked={surveyState[3].questions[0].answers[59].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer60} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain11}</h3>
                                <FormControlLabel value='3-0-60' control={<Radio checked={surveyState[3].questions[0].answers[60].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer61} />
                                <FormControlLabel value='3-0-61' control={<Radio checked={surveyState[3].questions[0].answers[61].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer62} />
                                <FormControlLabel value='3-0-62' control={<Radio checked={surveyState[3].questions[0].answers[62].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer63} />
                                <FormControlLabel value='3-0-63' control={<Radio checked={surveyState[3].questions[0].answers[63].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer64} />
                                <FormControlLabel value='3-0-64' control={<Radio checked={surveyState[3].questions[0].answers[64].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer65} />
                                <FormControlLabel value='3-0-65' control={<Radio checked={surveyState[3].questions[0].answers[65].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer66} />
                                <FormControlLabel value='3-0-66' control={<Radio checked={surveyState[3].questions[0].answers[66].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer67} />


                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain12}</h3>
                                <FormControlLabel value='3-0-67' control={<Radio checked={surveyState[3].questions[0].answers[67].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer68} />
                                <FormControlLabel value='3-0-68' control={<Radio checked={surveyState[3].questions[0].answers[68].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer69} />
                                <FormControlLabel value='3-0-69' control={<Radio checked={surveyState[3].questions[0].answers[69].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer70} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain13}</h3>
                                <FormControlLabel value='3-0-70' control={<Radio checked={surveyState[3].questions[0].answers[70].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer71} />
                                <FormControlLabel value='3-0-71' control={<Radio checked={surveyState[3].questions[0].answers[71].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer72} />
                                <FormControlLabel value='3-0-72' control={<Radio checked={surveyState[3].questions[0].answers[72].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer73} />
                                <FormControlLabel value='3-0-73' control={<Radio checked={surveyState[3].questions[0].answers[73].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer74} />
                                <FormControlLabel value='3-0-74' control={<Radio checked={surveyState[3].questions[0].answers[74].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer75} />
                                <FormControlLabel value='3-0-75' control={<Radio checked={surveyState[3].questions[0].answers[75].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer76} />
                                <FormControlLabel value='3-0-76' control={<Radio checked={surveyState[3].questions[0].answers[76].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer77} />
                                <FormControlLabel value='3-0-77' control={<Radio checked={surveyState[3].questions[0].answers[77].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer78} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain14}</h3>
                                <FormControlLabel value='3-0-78' control={<Radio checked={surveyState[3].questions[0].answers[78].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer79} />
                                <FormControlLabel value='3-0-79' control={<Radio checked={surveyState[3].questions[0].answers[79].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer80} />
                                <FormControlLabel value='3-0-80' control={<Radio checked={surveyState[3].questions[0].answers[80].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer81} />
                                <FormControlLabel value='3-0-81' control={<Radio checked={surveyState[3].questions[0].answers[81].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer82} />
                                <FormControlLabel value='3-0-82' control={<Radio checked={surveyState[3].questions[0].answers[82].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer83} />
                                <FormControlLabel value='3-0-83' control={<Radio checked={surveyState[3].questions[0].answers[83].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer84} />
                                <FormControlLabel value='3-0-84' control={<Radio checked={surveyState[3].questions[0].answers[84].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer85} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain15}</h3>
                                <FormControlLabel value='3-0-85' control={<Radio checked={surveyState[3].questions[0].answers[85].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer86} />
                                <FormControlLabel value='3-0-86' control={<Radio checked={surveyState[3].questions[0].answers[86].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer87} />
                                <FormControlLabel value='3-0-87' control={<Radio checked={surveyState[3].questions[0].answers[87].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer88} />
                                <FormControlLabel value='3-0-88' control={<Radio checked={surveyState[3].questions[0].answers[88].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer89} />
                                <FormControlLabel value='3-0-89' control={<Radio checked={surveyState[3].questions[0].answers[89].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer90} />
                                <FormControlLabel value='3-0-90' control={<Radio checked={surveyState[3].questions[0].answers[90].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer91} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain16}</h3>
                                <FormControlLabel value='3-0-91' control={<Radio checked={surveyState[3].questions[0].answers[91].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer92} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain17}</h3>
                                <h5>{language.questionaryRazboi.questionaryPage.tabFive.domain17}</h5>
                                <FormControlLabel value='3-0-92' control={<Radio checked={surveyState[3].questions[0].answers[92].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer93} />
                                <FormControlLabel value='3-0-93' control={<Radio checked={surveyState[3].questions[0].answers[93].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer94} />
                                <FormControlLabel value='3-0-94' control={<Radio checked={surveyState[3].questions[0].answers[94].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer95} />
                                <FormControlLabel value='3-0-95' control={<Radio checked={surveyState[3].questions[0].answers[95].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer96} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain18}</h3>
                                <FormControlLabel value='3-0-96' control={<Radio checked={surveyState[3].questions[0].answers[96].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer97} />
                                <FormControlLabel value='3-0-97' control={<Radio checked={surveyState[3].questions[0].answers[97].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer98} />
                                <FormControlLabel value='3-0-98' control={<Radio checked={surveyState[3].questions[0].answers[98].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer99} />
                                <FormControlLabel value='3-0-99' control={<Radio checked={surveyState[3].questions[0].answers[99].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer100} />
                                <FormControlLabel value='3-0-99' control={<Radio checked={surveyState[3].questions[0].answers[100].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer101} />
                                <FormControlLabel value='3-0-101' control={<Radio checked={surveyState[3].questions[0].answers[101].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer102} />
                                <FormControlLabel value='3-0-102' control={<Radio checked={surveyState[3].questions[0].answers[102].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer103} />
                                <FormControlLabel value='3-0-103' control={<Radio checked={surveyState[3].questions[0].answers[103].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer104} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain19}</h3>
                                <FormControlLabel value='3-0-104' control={<Radio checked={surveyState[3].questions[0].answers[104].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer105} />
                                <FormControlLabel value='3-0-105' control={<Radio checked={surveyState[3].questions[0].answers[105].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer106} />
                                <FormControlLabel value='3-0-106' control={<Radio checked={surveyState[3].questions[0].answers[106].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer107} />
                                <FormControlLabel value='3-0-107' control={<Radio checked={surveyState[3].questions[0].answers[107].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer108} />
                                <FormControlLabel value='3-0-108' control={<Radio checked={surveyState[3].questions[0].answers[108].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer109} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain20}</h3>
                                <FormControlLabel value='3-0-109' control={<Radio checked={surveyState[3].questions[0].answers[109].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer110} />
                                <FormControlLabel value='3-0-110' control={<Radio checked={surveyState[3].questions[0].answers[110].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer111} />
                                <FormControlLabel value='3-0-111' control={<Radio checked={surveyState[3].questions[0].answers[111].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer112} />
                                <FormControlLabel value='3-0-112' control={<Radio checked={surveyState[3].questions[0].answers[112].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer113} />

                                <h3>{language.questionaryRazboi.questionaryPage.tabFive.domain21}</h3>
                                <FormControlLabel value='3-0-113' control={<Radio checked={surveyState[3].questions[0].answers[113].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer114} />
                                <FormControlLabel value='3-0-114' control={<Radio checked={surveyState[3].questions[0].answers[114].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer115} />
                                <FormControlLabel value='3-0-115' control={<Radio checked={surveyState[3].questions[0].answers[115].selected} />} label={language.questionaryRazboi.questionaryPage.tabFive.answer116} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 5 */}
                {currentTab === 4 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabSix.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="FifthQuestion-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='4-0-0' control={<Radio checked={surveyState[4].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabSix.answer1} />
                                <FormControlLabel value='4-0-1' control={<Radio checked={surveyState[4].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabSix.answer2} />
                                <FormControlLabel value='4-0-2' control={<Radio checked={surveyState[4].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabSix.answer3} />
                                <FormControlLabel value='4-0-3' control={<Radio checked={surveyState[4].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabSix.answer4} />
                                <FormControlLabel value='4-0-4' control={<Radio checked={surveyState[4].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabSix.answer5} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/* Tab 6 */}
                {currentTab === 5 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabSeven.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Sixth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='5-0-0' control={<Radio checked={surveyState[5].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabSeven.answer1} />
                                <FormControlLabel value='5-0-1' control={<Radio checked={surveyState[5].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabSeven.answer2} />
                                <FormControlLabel value='5-0-2' control={<Radio checked={surveyState[5].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabSeven.answer3} />
                                <FormControlLabel value='5-0-3' control={<Radio checked={surveyState[5].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabSeven.answer4} />
                                <FormControlLabel value='5-0-4' control={<Radio checked={surveyState[5].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabSeven.answer5} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 6 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabEight.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Seventh-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='6-0-0' control={<Radio checked={surveyState[6].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabEight.answer1} />
                                <FormControlLabel value='6-0-1' control={<Radio checked={surveyState[6].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabEight.answer2} />

                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 7 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabNine.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Eighth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='7-0-0' control={<Radio checked={surveyState[7].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabNine.answer1} />
                                <FormControlLabel value='7-0-1' control={<Radio checked={surveyState[7].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabNine.answer2} />

                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 8 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabTen.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Nineth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='8-0-0' control={<Radio checked={surveyState[8].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer1} />
                                <FormControlLabel value='8-0-1' control={<Radio checked={surveyState[8].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer2} />
                                <FormControlLabel value='8-0-2' control={<Radio checked={surveyState[8].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer3} />
                                <FormControlLabel value='8-0-3' control={<Radio checked={surveyState[8].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer4} />
                                <FormControlLabel value='8-0-4' control={<Radio checked={surveyState[8].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer5} />
                                <FormControlLabel value='8-0-5' control={<Radio checked={surveyState[8].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer6} />
                                <FormControlLabel value='8-0-6' control={<Radio checked={surveyState[8].questions[0].answers[6].selected} />} label={language.questionaryRazboi.questionaryPage.tabTen.answer7} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 9 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabEleven.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Tenth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='9-0-0' control={<Radio checked={surveyState[9].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabEleven.answer1} />
                                <FormControlLabel value='9-0-1' control={<Radio checked={surveyState[9].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabEleven.answer2} />
                                <FormControlLabel value='9-0-2' control={<Radio checked={surveyState[9].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabEleven.answer3} />
                                <FormControlLabel value='9-0-3' control={<Radio checked={surveyState[9].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabEleven.answer4} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 10 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabTwelve.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Eleventh-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='10-0-0' control={<Radio checked={surveyState[10].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwelve.answer1} />
                                <FormControlLabel value='10-0-1' control={<Radio checked={surveyState[10].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwelve.answer2} />
                                <FormControlLabel value='10-0-2' control={<Radio checked={surveyState[10].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwelve.answer3} />
                                <FormControlLabel value='10-0-3' control={<Radio checked={surveyState[10].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwelve.answer4} />
                                <FormControlLabel value='10-0-4' control={<Radio checked={surveyState[10].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabTwelve.answer5} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 11 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabThirteen.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Twelvth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='11-0-0' control={<Radio checked={surveyState[11].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabThirteen.answer1} />
                                <FormControlLabel value='11-0-1' control={<Radio checked={surveyState[11].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabThirteen.answer2} />
                                <FormControlLabel value='11-0-2' control={<Radio checked={surveyState[11].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabThirteen.answer3} />
                                <FormControlLabel value='11-0-3' control={<Radio checked={surveyState[11].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabThirteen.answer4} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}> {language.questionaryRazboi.questionaryPage.tabTwo.actions.forward}</Button>

                        </Stack>
                    </div>
                    : null}
                {currentTab === 12 ?
                    <div>
                        <h1>{language.questionaryRazboi.questionaryPage.tabFourteen.question}</h1>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Twelvth-Chapter1"
                                name="radio-buttons-group"
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value='12-0-0' control={<Radio checked={surveyState[12].questions[0].answers[0].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer1} />
                                <FormControlLabel value='12-0-1' control={<Radio checked={surveyState[12].questions[0].answers[1].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer2} />
                                <FormControlLabel value='12-0-2' control={<Radio checked={surveyState[12].questions[0].answers[2].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer3} />
                                <FormControlLabel value='12-0-3' control={<Radio checked={surveyState[12].questions[0].answers[3].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer4} />
                                <FormControlLabel value='12-0-4' control={<Radio checked={surveyState[12].questions[0].answers[4].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer5} />
                                <FormControlLabel value='12-0-5' control={<Radio checked={surveyState[12].questions[0].answers[5].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer6} />
                                <FormControlLabel value='12-0-6' control={<Radio checked={surveyState[12].questions[0].answers[6].selected} />} label={language.questionaryRazboi.questionaryPage.tabFourteen.answer7} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryRazboi.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryRazboi.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => onCountTotal()}>{language.questionaryRazboi.questionaryPage.tabFourteen.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {currentTab === 13 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.title}:</h1>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart1} {total.toFixed(2)} {language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart2}</h3>

                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.rewardTitle}: {showResponse(total)}</h1>

                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandationTitle}:</h1>

                        <h4>{showRecomandation(total)}</h4>


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => window.location.href = window.location.origin}>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.buttonMenu}</Button>
                        </Stack>
                    </div>
                    : null}
            </div>
        </div>
    )
}
