import { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        borderStyle: 'solid',
        borderColor: '#5840BB',
        borderWidth: '2em',
        textAlign: 'left',
        padding: '40px',
        background: 'white',
        height: '70vh'
    },
    saveUserBtn: {
        float: 'left'
    }
});


export default function FullPredictResult() {
    const [language, setLanguage] = useState(null)
    const [result1, setResult1] = useState(null)
    const [result2, setResult2] = useState(null)
    const [result3, setResult3] = useState(null)
    const [result4, setResult4] = useState(null)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if(localStorage.getItem('language_JSON')) setLanguage(JSON.parse(localStorage.getItem('language_JSON')))
        if (localStorage.getItem('chestionar-1-result')) setResult1(localStorage.getItem('chestionar-1-result'))
        if (localStorage.getItem('chestionar-2-result')) setResult2(localStorage.getItem('chestionar-2-result'))
        if (localStorage.getItem('chestionar-3-result')) setResult3(localStorage.getItem('chestionar-3-result'))
        if (localStorage.getItem('chestionar-4-result')) setResult4(localStorage.getItem('chestionar-4-result'))
    }, [])

    useEffect(() => {
        setTotal((+result1 || 0) + (+result2 || 0) + (+result3 || 0) + (+result4 || 0))
    }, [result1, result2, result3, result4])

    const showResponse = useCallback(() => {
        if (total > 0 && total <= 119) {
            return language.fullPredictResultPage.result.valueOne
        }
        if (total > 119 && total <= 219) {
            return language.fullPredictResultPage.result.valueTwo
        }
        if (total > 219 && total <= 349) {
            return language.fullPredictResultPage.result.valueThree
        }
        if (total > 349 && total <= 400) {
            return language.fullPredictResultPage.result.valueFour
        }
    }, [total])

    const classes = useStyles()

    if(!language) return

    return (
        <div className={classes.container}>
            <div>
                {language.fullPredictResultPage.questionaryResultLabel} <b>{language.homePage.questionaryCards.calamnitati.title}</b>: {result1 ? `${result1} ${language.fullPredictResultPage.points}` : '-'}
            </div>
            <br />
            <div>
                {language.fullPredictResultPage.questionaryResultLabel} <b> {language.homePage.questionaryCards.riscRazboi.title}</b>: {result2 ? `${result2} ${language.fullPredictResultPage.points}` : '-'}
            </div>
            <br />
            <div>
                {language.fullPredictResultPage.questionaryResultLabel} <b> {language.homePage.questionaryCards.pandemie.title}</b>: {result3 ? `${result3} ${language.fullPredictResultPage.points}` : '-'}
            </div>
            <br />
            <div>
                {language.fullPredictResultPage.questionaryResultLabel} <b> {language.homePage.questionaryCards.crizaFinanciara.title}</b>: {result4 ? `${result4} ${language.fullPredictResultPage.points}` : '-'}
            </div>
            {total ? (
                <>
                    <div>
                        <h3>{language.fullPredictResultPage.total} – {total.toFixed(2)} {language.fullPredictResultPage.points} {language.fullPredictResultPage.din} 400 {language.fullPredictResultPage.posiblePoints}</h3>
                    </div>
                    <div>
                        {result1 && result2 && result3 && result4 ? (
                            <>
                                <h1>{language.fullPredictResultPage.result.label}: {showResponse()}</h1>
                                <h2>{language.fullPredictResultPage.barem.label}: </h2>
                                <ul>
                                    <li>{language.fullPredictResultPage.barem.valueOne}: <b>{language.fullPredictResultPage.result.valueOne}</b></li>
                                    <li>{language.fullPredictResultPage.barem.valueTwo}: <b>{language.fullPredictResultPage.result.valueTwo}</b></li>
                                    <li>{language.fullPredictResultPage.barem.valueThree}: <b>{language.fullPredictResultPage.result.valueThree}</b></li>
                                    <li>{language.fullPredictResultPage.barem.valueFour}: <b>{language.fullPredictResultPage.result.valueFour}</b></li>
                                </ul>
                            </>
                        ) : null}
                    </div>
                </>
            ) : null
            }
            <br></br>
            <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={() => window.location.href = window.location.origin}>{language.fullPredictResultPage.buttonMenu}</Button>
            </Stack>
        </div >
    )
}
