import React, { useState, useEffect, useCallback } from 'react'
import {
    Button,
    Stack,
    Checkbox,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Alert,
    TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        borderStyle: 'solid',
        borderColor: '#5840BB',
        borderWidth: '2em',
        textAlign: 'left',
        padding: '40px',
        background: 'white'
    },
    surveyContainer: {
        overflowX: 'auto',
        maxHeight: '70vh',
        background: 'white'
    }
});

const survey = [
    {
        tab: 0,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 2, selected: false },
                    { index: 1, value: 6, selected: false },
                    { index: 2, value: 11, selected: false },
                    { index: 3, value: 13, selected: false },
                    { index: 4, value: 14, selected: false },
                    { index: 5, value: 2, selected: false },
                    { index: 6, value: 15, selected: false },
                ]
            }
        ]
    },
    {
        tab: 1,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 8, selected: false },
                    { index: 1, value: 12, selected: false },
                    { index: 2, value: 10, selected: false },
                ]
            },
            {
                index: 1,
                answers: [
                    { index: 0, value: 2, selected: false },
                    { index: 1, value: 0, selected: false },
                ]
            },
            {
                index: 2,
                answers: [
                    { index: 0, value: 1, selected: false },
                    { index: 1, value: 2, selected: false },
                    { index: 2, value: 2, selected: false },
                    { index: 3, value: 2, selected: false },
                    { index: 4, value: 3, selected: false },
                    { index: 5, value: 4, selected: false },
                    { index: 6, value: 6, selected: false },
                ]
            },
        ]
    },
    {
        tab: 2,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 1, selected: false },
                    { index: 1, value: 5, selected: false },
                    { index: 2, value: 9, selected: false },
                    { index: 3, value: 10, selected: false },
                    { index: 4, value: 11, selected: false },
                    { index: 5, value: 12, selected: false },
                ]
            },
        ]
    },
    {
        tab: 3,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 15, selected: false },
                    { index: 1, value: 13, selected: false },
                    { index: 2, value: 13, selected: false },
                    { index: 3, value: 8, selected: false },
                    { index: 4, value: 1, selected: false },
                    { index: 5, value: 0, selected: false },
                ]
            },
        ]
    },
    {
        tab: 4,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 2, selected: false },
                    { index: 1, value: 2, selected: false },
                    { index: 2, value: 2, selected: false },
                    { index: 3, value: 2, selected: false },
                    { index: 4, value: 2, selected: false },
                    { index: 5, value: 3, selected: false },
                    { index: 6, value: 1, selected: false },
                    { index: 7, value: 2, selected: false },
                ]
            },
        ]
    },
    {
        tab: 5,
        questions: [
            {
                index: 0,
                answers: [
                    { index: 0, value: 2, selected: false },
                    { index: 1, value: 1, selected: false },
                    { index: 2, value: 2, selected: false },
                ]
            },
            {
                index: 1,
                answers: [
                    { index: 0, value: 3, selected: false },
                    { index: 1, value: 3, selected: false },
                    { index: 2, value: 2, selected: false },
                    { index: 3, value: 2, selected: false },
                    { index: 4, value: 2, selected: false },
                    { index: 5, value: 1, selected: false },
                    { index: 6, value: 2, selected: false },
                ]
            },
        ]
    },
]

export default function SurveyOne() {
    const classes = useStyles()
    const [language, setLanguage] = useState(null)
    const [surveyCode, setSuveyCode] = useState(null)
    const [surveyState, setSurveyState] = useState(survey)
    const [currentTab, setSelectedTab] = useState(0)
    const [error, setError] = useState(false)
    const [errorCode, setErrorCode] = useState(false)
    const [total, setTotal] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('language_JSON')) setLanguage(JSON.parse(localStorage.getItem('language_JSON')))
        if (!localStorage.getItem('chestionar1')) return
        setSuveyCode(localStorage.getItem('chestionar1'))
    }, [])

    const goToNextTab = useCallback(() => {
        if (!checkIfQuestionIsCompleted(surveyState[currentTab])) {
            setError(true)
            return
        } else setError(false)

        setSelectedTab(currentTab + 1)
    }, [currentTab, surveyState])

    const checkIfQuestionIsCompleted = (tab) => {
        let isCompleted = false
        if (tab.questions.length !== 1) {

            let haveAnyQuestionUncomplete = false
            for (let question of tab.questions) {
                if (question.answers.filter(answer => answer.selected).length === 0) {
                    haveAnyQuestionUncomplete = true
                }
            }
            isCompleted = !haveAnyQuestionUncomplete
        } else {
            for (let question of tab.questions) {
                for (let answer of question.answers) {
                    if (answer.selected) {
                        isCompleted = true
                    }
                }
            }
        }

        return isCompleted
    }

    const goToPreviousTab = useCallback(() => {
        setSelectedTab(currentTab - 1)
    }, [currentTab])

    const handleRadioChange = (event) => {
        if (!event.target.value) return
        const indexOfTab = event.target.value.split('-')[0]
        const indexOfQuestion = event.target.value.split('-')[1]
        const indexOfAnswer = event.target.value.split('-')[2]

        const tempSurveyState = [...surveyState]

        for (let answer of tempSurveyState[indexOfTab].questions[indexOfQuestion].answers) {
            if (answer.index !== +indexOfAnswer) {
                answer.selected = false
            } else answer.selected = true
        }

        setSurveyState(tempSurveyState)
    };

    const onChangeCheckboxState = (position) => {
        const indexOfTab = +position.split('-')[0]
        const indexOfQuestion = +position.split('-')[1]
        const indexOfAnswer = +position.split('-')[2]
        const tempSurveyState = [...surveyState]

        for (let answer of tempSurveyState[indexOfTab].questions[indexOfQuestion].answers) {
            if (answer.index === +indexOfAnswer) {
                answer.selected = !answer.selected
            }
        }
        setSurveyState(tempSurveyState)
    }

    const onCountTotal = useCallback(() => {
        if (!checkIfQuestionIsCompleted(surveyState[5])) {
            setError(true)
            return
        } else setError(false)

        let conter = 0
        for (let tab of surveyState) {
            for (let question of tab.questions) {
                for (let answer of question.answers) {
                    if (answer.selected) conter += answer.value
                }
            }
        }
        localStorage.setItem('chestionar-1-result', +conter.toFixed(2))
        setSelectedTab(currentTab + 1)
        setTotal(conter)
        consumeTheCode(surveyCode, +conter)
    }, [surveyState, surveyCode])


    const consumeTheCode = (code, counter) => {
        if (localStorage.getItem('chestionar1')) {
            localStorage.removeItem('chestionar1')
        }

        let bodyHTML = ` <div>
        <h1>Rezultat:</h1>

        <h3>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart1} ${counter.toFixed(2)} ${language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart2}!</h3>

        <h1>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.rewardTitle}: ${showResponse(counter)}</h1>

        <h1>${language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandationTitle}:</h1>

        <h4>${showRecomandation(counter)}</h4>
        </div>`

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                answers: [],
                subject: 'Rezultat chestionar',
                message: bodyHTML,
                results: {
                    label: showResponse(counter),
                    value: counter
                }
            })
        }
        fetch(window.baseURL + '/questionary/complete', requestOptions)
            .then(response => response.json())
    }

    const onValidateSurveyCode = useCallback(() => {
        if (!surveyCode) {
            setErrorCode(true)
            return
        }
        const requestOptions = {
            method: 'POST',
            body: new URLSearchParams({ code: surveyCode })
        };
        fetch(window.baseURL + '/questionary/check', requestOptions)
            .then(response => response.json())
            .then(body => {
                if (body.result === true) {
                    setErrorCode(false)
                    setSelectedTab(0)
                } else setErrorCode(true)
            })
    }, [surveyCode])

    const showResponse = (total) => {
        if (total > 0 && total <= 30) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward1
        }
        if (total > 30 && total <= 50) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward2
        }
        if (total > 50 && total <= 75) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward3
        }
        if (total > 75 && total <= 100) {
            return language.questionaryCalamnitatiPage.questionaryPage.resultTab.reward4
        }
    }

    const showRecomandation = (total) => {
        if (total > 0 && total <= 30) {
            return (
                <div>
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation1}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r11}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r12}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r13}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r14}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r15}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r16}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r1C}</p>
                </div>
            )

        }
        if (total > 30 && total <= 50) {
            return (
                <div>
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation2}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r21}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r22}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r23}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r24}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r25}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r2c}</p>
                </div>
            )
        }
        if (total > 50 && total <= 75) {
            return (
                <div>
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation3}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r31}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r32}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r33}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r34}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r35}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r3c}</p>
                </div >
            )
        }
        if (total > 75 && total <= 100) {
            return (
                < div >
                    <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandation4}</h3>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r41}</p>
                    <p>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.r42}</p>
                </div >
            )
        }
    }
    if (!language) return

    return (
        <div className={classes.container}>
            <div className={classes.surveyContainer}>
                {currentTab === -1 ? (
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabOnePresentation.title}</h1>
                        <h4>
                            <div>{language.questionaryCalamnitatiPage.questionaryPage.tabOnePresentation.criterii}</div>
                            <br />
                        </h4>
                        <label>{language.questionaryCalamnitatiPage.questionaryPage.tabOnePresentation.pleaseAddCode}</label>
                        <br />
                        <TextField
                            style={{ maxWidth: 400 }}
                            fullWidth
                            id="outlined-basic"
                            label={null}
                            variant="outlined"
                            value={surveyCode}
                            onChange={(e) => setSuveyCode(e?.target?.value)}
                        />
                        <br />
                        <br />
                        {errorCode ? (<Alert severity="error">Incorrect code</Alert>) : null}
                        <br />
                        <Button
                            className={classes.saveUserBtn}
                            variant='contained'
                            color='primary'
                            onClick={() => onValidateSurveyCode()}
                        >
                            {language.questionaryCalamnitatiPage.questionaryPage.tabOnePresentation.next}
                        </Button>
                    </div>
                ) : null}
                {/*  Tab 1 */}
                {currentTab === 0 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.question}</h1>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="firstQuestion"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='0-0-0' control={<Radio checked={surveyState[0].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer1} />
                                <FormControlLabel value='0-0-1' control={<Radio checked={surveyState[0].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer2} />
                                <FormControlLabel value='0-0-2' control={<Radio checked={surveyState[0].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer3} />
                                <FormControlLabel value='0-0-3' control={<Radio checked={surveyState[0].questions[0].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer4} />
                                <FormControlLabel value='0-0-4' control={<Radio checked={surveyState[0].questions[0].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer5} />
                                <FormControlLabel value='0-0-5' control={<Radio checked={surveyState[0].questions[0].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer6} />
                                <FormControlLabel value='0-0-6' control={<Radio checked={surveyState[0].questions[0].answers[6].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabTwo.answer7} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 2 */}
                {currentTab === 1 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabThree.question}</h1>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.tabThree.domain1}</h3>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="secondQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='1-0-0' control={<Radio checked={surveyState[1].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer1} />
                                <FormControlLabel value='1-0-1' control={<Radio checked={surveyState[1].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer2} />
                                <FormControlLabel value='1-0-2' control={<Radio checked={surveyState[1].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer3} />
                            </RadioGroup>
                        </FormControl>


                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.tabThree.domain2}</h3>
                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="secondQuestion-Chapter2"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='1-1-0' control={<Radio checked={surveyState[1].questions[1].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer4} />
                                <FormControlLabel value='1-1-1' control={<Radio checked={surveyState[1].questions[1].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer12} />
                            </RadioGroup>
                        </FormControl>
                        <div></div>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.tabThree.domain3}</h3>

                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="secondQuestion-Chapter3"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='1-2-0' control={<Radio checked={surveyState[1].questions[2].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer5} />
                                <FormControlLabel value='1-2-1' control={<Radio checked={surveyState[1].questions[2].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer6} />
                                <FormControlLabel value='1-2-2' control={<Radio checked={surveyState[1].questions[2].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer7} />
                                <FormControlLabel value='1-2-3' control={<Radio checked={surveyState[1].questions[2].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer8} />
                                <FormControlLabel value='1-2-4' control={<Radio checked={surveyState[1].questions[2].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer9} />
                                <FormControlLabel value='1-2-5' control={<Radio checked={surveyState[1].questions[2].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer10} />
                                <FormControlLabel value='1-2-6' control={<Radio checked={surveyState[1].questions[2].answers[6].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabThree.answer11} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 3 */}
                {currentTab === 2 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabFour.question}</h1>

                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="thirdQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='2-0-0' control={<Radio checked={surveyState[2].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer1} />
                                <FormControlLabel value='2-0-1' control={<Radio checked={surveyState[2].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer2} />
                                <FormControlLabel value='2-0-2' control={<Radio checked={surveyState[2].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer3} />
                                <FormControlLabel value='2-0-3' control={<Radio checked={surveyState[2].questions[0].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer4} />
                                <FormControlLabel value='2-0-4' control={<Radio checked={surveyState[2].questions[0].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer5} />
                                <FormControlLabel value='2-0-5' control={<Radio checked={surveyState[2].questions[0].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFour.answer6} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 4 */}
                {currentTab === 3 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabFive.question}</h1>

                        <FormControl>
                            <RadioGroup
                                onChange={handleRadioChange}
                                aria-labelledby="FourthQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='3-0-0' control={<Radio checked={surveyState[3].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer1} />
                                <FormControlLabel value='3-0-1' control={<Radio checked={surveyState[3].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer2} />
                                <FormControlLabel value='3-0-2' control={<Radio checked={surveyState[3].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer3} />
                                <FormControlLabel value='3-0-3' control={<Radio checked={surveyState[3].questions[0].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer4} />
                                <FormControlLabel value='3-0-4' control={<Radio checked={surveyState[3].questions[0].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer5} />
                                <FormControlLabel value='3-0-5' control={<Radio checked={surveyState[3].questions[0].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabFive.answer6} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/*  Tab 5 */}
                {currentTab === 4 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabSix.question}</h1>

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="FifthQuestion-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='4-0-0' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-0')} checked={surveyState[4].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer1} />
                                <FormControlLabel value='4-0-1' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-1')} checked={surveyState[4].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer2} />
                                <FormControlLabel value='4-0-2' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-2')} checked={surveyState[4].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer3} />
                                <FormControlLabel value='4-0-3' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-3')} checked={surveyState[4].questions[0].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer4} />
                                <FormControlLabel value='4-0-4' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-4')} checked={surveyState[4].questions[0].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer5} />
                                <FormControlLabel value='4-0-5' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-5')} checked={surveyState[4].questions[0].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer6} />
                                <FormControlLabel value='4-0-6' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-6')} checked={surveyState[4].questions[0].answers[6].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer7} />
                                <FormControlLabel value='4-0-7' control={<Checkbox onClick={() => onChangeCheckboxState('4-0-7')} checked={surveyState[4].questions[0].answers[7].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSix.answer8} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }

                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => goToNextTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {/* Tab 6 */}
                {currentTab === 5 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.tabSeven.question}</h1>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.tabSeven.domain1}</h3>

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Sixth-Chapter1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='5-0-0' control={<Checkbox onClick={() => onChangeCheckboxState('5-0-0')} checked={surveyState[5].questions[0].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer1} />
                                <FormControlLabel value='5-0-1' control={<Checkbox onClick={() => onChangeCheckboxState('5-0-1')} checked={surveyState[5].questions[0].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer2} />
                                <FormControlLabel value='5-0-2' control={<Checkbox onClick={() => onChangeCheckboxState('5-0-2')} checked={surveyState[5].questions[0].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer3} />
                            </RadioGroup>
                        </FormControl>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.tabSeven.domain2}</h3>

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="Sixth-Chapter2"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value='5-1-0' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-0')} checked={surveyState[5].questions[1].answers[0].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer4} />
                                <FormControlLabel value='5-1-1' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-1')} checked={surveyState[5].questions[1].answers[1].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer5} />
                                <FormControlLabel value='5-1-2' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-2')} checked={surveyState[5].questions[1].answers[2].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer6} />
                                <FormControlLabel value='5-1-3' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-3')} checked={surveyState[5].questions[1].answers[3].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer7} />
                                <FormControlLabel value='5-1-4' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-4')} checked={surveyState[5].questions[1].answers[4].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer8} />
                                <FormControlLabel value='5-1-5' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-5')} checked={surveyState[5].questions[1].answers[5].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer9} />
                                <FormControlLabel value='5-1-6' control={<Checkbox onClick={() => onChangeCheckboxState('5-1-6')} checked={surveyState[5].questions[1].answers[6].selected} />} label={language.questionaryCalamnitatiPage.questionaryPage.tabSeven.answer10} />
                            </RadioGroup>
                        </FormControl>

                        <br />
                        <br />

                        {
                            error ? (
                                <>
                                    <Alert severity="error">{language.questionaryCalamnitatiPage.questionaryPage.error}</Alert>
                                    <br />
                                    <br />
                                </>
                            ) : null
                        }
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => goToPreviousTab()}>{language.questionaryCalamnitatiPage.questionaryPage.tabTwo.actions.back}</Button>
                            <Button variant="contained" onClick={() => onCountTotal()}>{language.questionaryCalamnitatiPage.questionaryPage.tabSeven.actions.forward}</Button>
                        </Stack>
                    </div>
                    : null}
                {currentTab === 6 ?
                    <div>
                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.title}:</h1>

                        <h3>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart1} {total.toFixed(2)} {language.questionaryCalamnitatiPage.questionaryPage.resultTab.descriptionPart2}</h3>


                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.rewardTitle}: {showResponse(total)}</h1>

                        <h1>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.recomandationTitle}:</h1>

                        <h4>{showRecomandation(total)}</h4>


                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => window.location.href = window.location.origin}>{language.questionaryCalamnitatiPage.questionaryPage.resultTab.buttonMenu}</Button>
                        </Stack>
                    </div>
                    : null}
            </div>
        </div>
    )
}
