const languages = [
    {
        id: 'ro',
        homePage: {
            title: 'Despre  Predict-On',
            content1: 'PREDICT-on este un program de analiza de date si calcul, pentru a-ti oferi o perspectiva asupra situatiei companiei tale in caz de crize economice,  razboi, calamnitati naturale sau pandemii. Practic, iti ofera o imagine cat mai clara despre situatia actuala si posibile solutii pentru viitor. ',
            content2: 'Acest program se bazeaza pe culegere de date si informatii, studii si analiza istoricului, pentru ultimii 100 de ani, in majoritatea domeniilor de activitate. ',
            content3: 'In anul 2018 a fost lansata ideea si au inceput cercetarile. O echipa de tineri au cules date si informatii, iar manageri cu experienta au intocmit un tipar al predictilitatii , bazat pe date economice si istoric al fiecarui domeniu de activitate. In final a rezultat programul Predict. ',
            buttonAnalizaPersonalizata: 'Solicită Analiză Personalizată / Contact',
            buttonBuyFullPredict: 'Cumpără Full Predict',
            buttonFullPredictResult: 'Rezultate Full Predict',
            free: 'Gratuit',
            questionaryCards: {
                calamnitati: {
                    title: 'CALAMNITĂȚI NATURALE',
                    description: 'Incendii, Inundatii, Seceta, Furtuni, Cutremure, Alunecari de teren, Eruptii vulcanice, alte riscuri  vor fi analizate pentru tine. Trebuie sa stii ! Rezultatul pe loc !',
                    buttonBuyCode: 'Cumpără un cod',
                    buttonUseCode: 'Folosește codul'
                },
                riscRazboi: {
                    title: 'RISC ÎN SITUAȚIE DE RĂZBOI',
                    description: 'Un conflict armat pare departe de tine ? Istoria spune altceva. Cât de pregătită este afacerea ta în caz de război ? Află in 5 minute !',
                    buttonBuyCode: 'Cumpără un cod',
                    buttonUseCode: 'Folosește codul'
                },
                pandemie: {
                    title: 'PANDEMIE',
                    description: 'Odată cu evoluția omenirii au apărut periodic viruși și boli noi. Pandemiile sunt un risc actual . Cum vei proceda? Ești Pregatit pentru acesta ? Completează formularul !',
                    buttonBuyCode: 'Cumpără un cod',
                    buttonUseCode: 'Folosește codul'
                },
                crizaFinanciara: {
                    title: 'CRIZĂ FINANCIARĂ',
                    description: 'La fiecare 10 ani este o recesiune economică. La fiecare 30 de ani este o criză economică majoră. Ești pregătit pentru a face fața ? Consultă Predict si vei afla in 5 minute !',
                    buttonBuyCode: 'Cumpără un cod',
                    buttonUseCode: 'Folosește codul'
                }
            },
            analizeModal: {
                title: 'Vă rugăm să completați formularul de mai jos',
                firstName: 'Prenume',
                lastName: 'Nume',
                email: 'Email',
                cancel: 'Anulează',
                send: 'Trimite',
                fullPredictBuyContent: 'Analiza Completa ! Cumpara acum Full Predict si vei avea toate cele 4 programe de analiza, pentru o predictie completa ! Vei afla situatia reala a companiei tale pentru toate riscurile prevazute de Predict-On.',
                fullPredictInstructions: 'Mai jos aveți codurile pentru chestionare. Vă rugăm să salvați preventiv codurile în cazul în care nu primiți niciun email cu acestea.',
                codeFor: 'Cod pentru',
                error: 'Ne pare rău, dar ceva nu a mers bine!',
                goToMenu: 'Mergi la meniu'
            }
        },
        fullPredictResultPage: {
            questionaryResultLabel: 'Rezultat chestionar',
            points: 'puncte',
            total: 'Total',
            din: 'din',
            posiblePoints: 'puncte posibile',
            result: {
                label: 'Rezultat',
                valueOne: 'Nesatisfacator',
                valueTwo: 'Acceptabil',
                valueThree: 'Bine',
                valueFour: 'Foarte Bine',
            },
            barem: {
                label: 'Barem',
                valueOne: 'pana la 119 puncte inclusiv',
                valueTwo: 'intre 120 - 219 puncte',
                valueThree: 'intre 220 – 349 puncte',
                valueFour: 'Intre 350 – 400 puncte',
            },
            buttonMenu: 'Meniu'
        },
        questionaryCalamnitatiPage: {
            buyCodePage: {
                title: 'Vă rugăm să completați formularul de mai jos',
                firstName: 'Prenume',
                lastName: 'Nume',
                email: 'Email',
                next: 'Înainte',
                afterPayment: {
                    title: 'Mai jos aveți codul pentru chestionar. Vă rugăm să salvați preventiv codul în cazul în care nu primiți niciun email cu acesta.',
                    codeFor: 'Codul pentru',
                    questionary: 'chestionar',
                    error: 'Ne pare rău, dar ceva nu a mers bine!',
                    goToMenu: 'Mergi la meniu'
                }
            },
            questionaryPage: {
                error: 'Vă rugăm să alegeți un răspuns!',
                tabOnePresentation: {
                    title: 'Eficiența companiei în situații de calamnități naturale.',
                    criterii: 'Criterii de verificare : puncte de la 1 la 100',
                    pleaseAddCode: 'Vă rugăm să adăugați codul chestionarului',
                    next: 'Înainte'
                },
                tabTwo: {
                    question: '1. Zonă cu risc seismic (alege o singură variantă)',
                    answer1: 'au fost cutremure în ultimii 10 ani',
                    answer2: 'au fost cutremure în urmă cu 10-30 ani',
                    answer3: 'au fost cutremure în urmă cu 30-50 ani',
                    answer4: 'au fost cutremure în urmă cu 50-100 ani',
                    answer5: 'au fost cutremure în urmă cu 100-500 ani',
                    answer6: 'există punct/sistem de alertare seismic în zonă',
                    answer7: 'nu se cunoaste un istoric pentru cutremure în zonă',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabThree: {
                    question: '2. Zona cu risc de inundatii',
                    domain1: 'Situatii (alege o singura varianta)',
                    answer1: 'nu au existat inundatii in zona geografica in ultimii 5 ani',
                    answer2: 'nu se cunosc situatii de inundatii in trecut',
                    answer3: 'nu au existat inundatii in zona geografica in ultimii 20 ani',

                    domain2: 'Prevenire',
                    answer4: 'au existat inundatii in zona si sau luat masuri serioase pentru prevenire',
                    answer12: 'nu cunosc dacă au existat inundații în zona și dacă s-au luat măsuri serioase pentru prevenire',

                    domain3: 'Amplasare (alege o singura varianta)',
                    answer5: 'amplasare langa o apa curgatoare',
                    answer6: 'amplasare langa un lac',
                    answer7: 'amplasare pe malul marii',
                    answer8: 'amplasare pe malul oceanului',
                    answer9: 'amplasare la baza unui munte',
                    answer10: 'amplasare in panta , sau pe poalele unui deal',
                    answer11: 'amplasare in zona unde nu poate exista riscul de inundatie',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFour: {
                    question: '3. Zona cu risc alunecari de teren (alege o singura varianta)',
                    answer1: 'au existat alunecari de teren in ultimii 10 ani',
                    answer2: 'au existat alunecari de teren in urma cu 10-30 ani',
                    answer3: 'au existat alunecari de teren in urma cu 30-50 ani',
                    answer4: 'au existat alunecari de teren in urma cu 50-100 ani',
                    answer5: 'a au existat alunecari de teren in urma cu 100-500 ani',
                    answer6: 'nu am cunostinta de alunecari de teren',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFive: {
                    question: '4. Risc de Furtuni, Vanturi puternice (alege o singura varianta)',
                    answer1: 'nu sunt furtuni in zona',
                    answer2: 'nu au fost furtuni semnificative in ultimii 5 ani',
                    answer3: 'furtunile anterioare sunt de intensitate: mica',
                    answer4: 'furtunile anterioare sunt de intensitate: medie',
                    answer5: 'furtunile anterioare sunt de intensitate: mare',
                    answer6: 'sunt furtuni si vanturi puternice in fiecare an',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSix: {
                    question: '5. Risc de Seceta (cumulare puncte)',
                    answer1: 'nu sesizam o crestere a temperaturii aerului in anotimpul calduros , in ultimii 20 ani',
                    answer2: 'in ultimii 5 ani nu a fost un volum de precipitatii mai scazut, comparativ cu ultimii 20 ani',
                    answer3: 'nu au fost incendii de vegetatie in ultimii 3 ani',
                    answer4: 'nu sunt schimbari in structura precipitatiilor, comparativ cu anul trecut( cantitatea de precipitatii este aproximativ in acelasi volum si in aceesi perioada calendaristica ) daca sunteti intr-o zona unde ninge, cantitatea de zapada din iarna trecuta nu a fost mai mica ca si in anul anterior',
                    answer5: 'daca sunteti intr-o zona unde ninge, cantitatea de zapada din iarna trecuta nu a fost mai mica ca si in anul anterior',
                    answer6: 'in anul anterior nu a fost seceta',
                    answer7: 'pe o arie de 50 km , nu a fost redusa semnificativ suprafata de paduri/vegetatie inalta, in ultimii 20 ani',
                    answer8: 'nu am observat o scadere semnificativa a nivelului apei, in rau, lac, fantana, in anii precedenti',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSeven: {
                    question: '6. Incendii (cumulare de puncte)',
                    domain1: 'Despre aria geografica',
                    answer1: 'sunt intr-o zona in care nu au existat in ultimii 5 ani incendii de vegetatie',
                    answer2: 'in zona nu sunt temperaturi mari in anotimpul cald',
                    answer3: 'in zona nu este seceta in anotimpul cald',
                    domain2: 'Despre compania mea',
                    answer4: 'dispun de sistem antiincendiu, inclusiv alarma',
                    answer5: 'dispun de o polita de asigurare pentru risc de incendiu',
                    answer6: 'pe arie de 20 m exista hidrant pentru incendii',
                    answer7: 'dispun de sursa de apa pe proprietate',
                    answer8: 'in constructia cladirilor mele s-au folosit si materiale inflamabile, dar sub 20% din totalul constructiei',
                    answer9: 'cladirea dispune de paratraznet',
                    answer10: 'cladirea dispune de protectie la panoul de sigurante',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                resultTab: {
                    title: 'Rezultat',
                    emailTitle: 'Rezultat Chestionar',
                    descriptionPart1: 'Ați obținut un punctaj de',
                    descriptionPart2: 'puncte!',
                    descriptionPart3: 'Totodată v-am trimis și un email cu rezultatul.',
                    rewardTitle: 'Calificativ',
                    reward1: 'Nesatisfacator',
                    reward2: 'Satisfacator',
                    reward3: 'Bine',
                    reward4: 'Foarte bine',
                    recomandationTitle: 'Recomandare',
                    recomandation1: 'Trebuie executate schimbari imediate in cadrul afacerii, revizuite amanuntitdetaliile si se vor lua decizii ce pot preveni situatii neplacute si asigura viitorul afacerii. Nu este foarte dificil, urmeaza pasii de mai jos:',
                    r11: 'Sa revenim la chestionar si la fiecare grupa de intrebari, observa in ce situatie puteti obtine punctaj mai mare. Realizeaza ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r12: 'Observa riscurile pe care le are activitatea ta. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa opresti sau sa fie afectata semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r13: 'Observa avantajele/punctele pozitive  pe care le are afacerea dumneavoastra. Vei incerca sa dezvoltati aceste puncte pozitive, fara a iti asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r14: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r15: 'Desi, observatiile de mai sus par simple, avand in vedere punctajul care s-a obtinut initial, vor avea un impact major in cadrul afacerii. Dar aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating mai bun in relatia cu institutiile finaciare.',
                    r16: 'Se fac modificarile in cadrul afacerii si se poate reveni, pentru o noua previziune.',
                    r1C: 'Totodata se poate solicita o analiza personalizata .',
                    recomandation2: 'Este potrivit sa se revizuiasca activitatea afacerii si sa aduci mici schimbari , pentru o previziune mai buna. Nu este foarte dificil, urmeaza pasii de mai jos :',
                    r21: 'Revenim la chestionar si la fiecare grupa de intrebari observa in ce situatie poti obtine punctaj mai mare. Vei realiza ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r22: 'Observa riscurile pe care le are activitatea. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa fie oprita sau sa afectata semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r23: 'Observa avantajele/punctele pozitive  pe care le are afacerea ta. Vei incerca sa dezvolti aceste puncte pozitive, fara a iti asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r24: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r25: 'Desi, observatiile de mai sus par simple, avand in vedere punctajul care a fost obtinut initial, vor avea un impact semnificativ in cadrul afacerii. Dar aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating mai bun in relatia cu institutiile finaciare.',
                    r2c: 'Se vor face modificarile in cadrul afacerii si se poate reveni, pentru o noua previziune.Totodata poti solicita o analiza personalizata .',
                    recomandation3: 'Afacerea ta este intr-o situatie de risc acceptabila, normala, in conditiile de analiza ale acestui Program. Nu trebuie sa iti faci griji, in timp mai poti  aduce imbunatatiri, cu atentia indreptata asupra detaliilor. Daca doresti sa obtii un punctaj FOARTE BINE , poti urma , urmati pasii de mai jos ',
                    r31: 'Revenim la chestionar si la fiecare grupa de intrebari observam in ce situatie poti obtine punctaj mai mare. Realizam ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r32: 'Observam riscurile pe care le are activitatea companiei. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa oprim sau sa afectam semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r33: 'Observam avantajele/punctele pozitive  pe care le are afacerea. Vei incerca sa dezvoltati aceste puncte pozitive, fara a va asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r34: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r35: 'Avand in vedere punctajul care la fost obtinut initial, schimbarile efectuate pentru calificativul FOARTE BINE , vor avea un impact moderat in cadrul afacerii. Aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating maxim in relatia cu institutiile finaciare.',
                    r3c: 'Dupa ce se fac  modificarile in cadrul afacerii se poate reveni, pentru o noua previziune. Totodata se poate solicita o analiza personalizata .',
                    recomandation4: 'Felicitari ! Afacerea ta este protejata de riscurile cuprinse in acest program. Succes pe mai departe ! Totodata puteti solicita o analiza personalizata .',
                    r41: 'A fost analizat riscul din toate punctele de vedere cunoscute in momentul actual, cuprinse in acest program de analiza, cu un istoric al riscului in afaceri din ultimii 100 de ani. ',
                    r42: 'Au fost luate in calcul o multitudine de domenii, dintre care amintim : Tipul de Afacere , Aria Geografica, Dimensiunea si Dezvoltarea Afacerilor,  Clima, Pregatire Profesionala, Domeniul Finaciar Contabil.',
                    buttonMenu: 'Meniu'
                }
            }
        },
        questionaryRazboi: {
            buyCodePage: {
                title: 'Vă rugăm să completați formularul de mai jos',
                firstName: 'Prenume',
                lastName: 'Nume',
                email: 'Email',
                next: 'Înainte',
                afterPayment: {
                    title: 'Mai jos aveți codul pentru chestionar. Vă rugăm să salvați preventiv codul în cazul în care nu primiți niciun email cu acesta.',
                    codeFor: 'Codul pentru',
                    questionary: 'chestionar',
                    error: 'Ne pare rău, dar ceva nu a mers bine!',
                    goToMenu: 'Mergi la meniu'
                }
            },
            questionaryPage: {
                error: 'Vă rugăm să alegeți un răspuns!',
                tabOnePresentation: {
                    title: 'Predictibilitatea afacerilor in caz de razboi, pentru profesionisti si companii .',
                    criterii: 'Criterii de verificare : puncte de la 1 la 100',
                    question: 'Ce dorim sa determinam ?',
                    answer: 'Capabilitatea clientului de a trece peste o situatie de razboi( conflict armat, revolutii, ocupatie straina, etc), totodata punctele unde trebuie sa intervina pentru a reusi sa depaseasca cu succes un asemenea moment.',
                    pleaseAddCode: 'Vă rugăm să adăugați codul chestionarului',
                    next: 'Înainte'
                },
                tabTwo: {
                    question: '1. Vechimea',
                    answer1: 'sub 1 an',
                    answer2: '1-3 ani',
                    answer3: '3-5 ani',
                    answer4: '5-15 ani',
                    answer5: '15- 30 ani',
                    answer6: 'peste 30 ani',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabThree: {
                    question: '2. Lichiditati, rezerve (media lichiditatilor pe ultimele 12 luni, din actele contabile)',
                    domain1: 'Situatii (alege o singura varianta)',
                    answer1: 'sub 0,8 %',
                    answer2: 'intre 0,8 - 1 %',
                    answer3: 'intre 1 - 1,5 %',
                    answer4: 'intre 1,5 - 2 %',
                    answer5: 'intre 2 - 2,5 %',
                    answer6: 'peste 2,5 %',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFour: {
                    question: '3. Marimea companiei :',
                    answer1: 'cifra de afaceri anuala pana in 120.000 Euro',
                    answer2: 'cifra de afaceri anuala intre 120.000 - 500.000 Euro',
                    answer3: 'cifra de afaceri anuala intre 500.000 - 1.000.000 Euro',
                    answer4: 'cifra de afaceri anuala intre 1.000.000 - 5.000.000 Euro',
                    answer5: 'cifra de afaceri anuala intre 5.000.000 - 50.000.000 Euro',
                    answer6: 'cifra de afaceri anuala intre 50.000.000 - 150.000.000 Euro',
                    answer7: 'cifra de afaceri anuala peste 150.000.000 Euro',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFive: {
                    question: '4. Tipul de afacere:',
                    description: 'Tipul de afacere se alege un obiect de activitate prepondrent . Daca situatia finala nu este multumitoare si aveti o activitate secundara , incercati sa introduceti aceasta activitate ca fiind principala si determinati care dintre activitati este mai eficienta .',

                    domain1: 'Explotarea resurselor naturale vegetale si animale :',
                    answer1: 'apicultura',
                    answer2: 'avicultura',
                    answer3: 'horticultura: Pomicultura, Viticultura, Oenologia, Legumicultura, Floricultura, Arhitectură peisagistică, Dendrologia',
                    answer4: 'sericicultura(cultura matasii)',
                    answer5: 'agricultura',
                    answer6: 'vanatoarea',
                    answer7: 'silvicultura',
                    answer8: 'exploatarea forestiera',
                    answer9: 'piscicultura',
                    answer10: 'acvacultura',

                    domain2: 'Industrii :',
                    answer11: 'industria constructiei de masini , aparate electrice',

                    domain3: 'Industria IT :',
                    answer12: 'cercetare dezvoltare',
                    answer13: 'informatica',
                    answer14: 'programare software / hardware',
                    answer15: 'retele de socializare',
                    answer16: 'aplicatii mobile',
                    answer17: 'web designer',
                    answer18: 'procesare',
                    answer19: 'limbaje de programare',
                    answer20: 'electronica',
                    answer21: 'alte activitati',

                    domain4: 'Productia industrială a resurselor naturale :',
                    answer22: 'industria hidrocarburilor - petrol , gaze naturale , carbuni',
                    answer23: 'extractia carbunilor , minereurilor',
                    answer24: 'productie materie prima',
                    answer25: 'industria cauciucului',
                    answer26: 'industria chimica / petrochimica',
                    answer27: 'industria energetica - energie solara , energie eoliana , hidrocentrale',
                    answer28: 'instrustria siderurgica / metalurgica',
                    answer29: 'industria materialelor pretioase',
                    answer30: 'alte resurse',

                    domain5: 'Industria Alimentara :',
                    answer31: 'alimente de baza : apa, paine, ulei, zahar',
                    answer32: 'alimente aditionale: dulciuri, sucuri',
                    answer33: 'productie marfuri de larg consum',
                    answer34: 'industria bauturilor alcoolice',
                    answer35: 'industria tutunului',
                    answer36: 'industria produselor lactate',
                    answer37: 'industria carnii',
                    answer38: 'industria legumelor, fructelor',
                    answer39: 'alte produse',

                    domain6: 'Industria Usoara :',
                    answer40: 'imbracaminte',
                    answer41: 'incaltaminte',
                    answer42: 'pielarie',
                    answer43: 'textile',
                    answer44: 'confectii',
                    answer45: 'hârtia și fabricarea articolelor din hârtie',
                    answer46: 'altele',

                    domain7: 'Industria materialelor de constructie :',
                    answer47: 'Productie utilaje',
                    answer48: 'Alte ramuri',

                    domain8: 'Servicii:',
                    answer49: 'servisare tehnica calcul',
                    answer50: 'service auto/moto',
                    answer51: 'service aparatura casnica',
                    answer52: 'service diverse',

                    domain9: 'Sanatate , invatamant, alimentatie:',
                    answer53: 'hoteluri , restaurante',
                    answer54: 'invatamant',
                    answer55: 'sanatate',

                    domain10: 'Telecomunicatii, audio, video :',
                    answer56: 'telefonie',
                    answer57: 'statii emisie- receptie',
                    answer58: 'televiziune',
                    answer59: 'cinematografe',
                    answer60: 'prelucrare audio, video',

                    domain11: 'Servicii imobiliare, contructii , inchirieri :',
                    answer61: 'design',
                    answer62: 'arhitectura',
                    answer63: 'proiectare',
                    answer64: 'tranzactii imobiliare',
                    answer65: 'inchirieri',
                    answer66: 'inchirieri echipament / masini',
                    answer67: 'inginerie',

                    domain12: 'Posta, Consultanta, Publicitate :',
                    answer68: 'consultanta',
                    answer69: 'publicitate',
                    answer70: 'altele',

                    domain13: 'Utilitati, mediu, deseuri :',
                    answer71: 'colectare, prelucrare, transport deseuri',
                    answer72: 'distributie apa',
                    answer73: 'energie electrica',
                    answer74: 'caldura',
                    answer75: 'reciclare',
                    answer76: 'intretinere si curatenie',
                    answer77: 'servicii sanitare',
                    answer78: 'altele',

                    domain14: 'Infrumusetare si intretinere :',
                    answer79: 'frizerie – coafura',
                    answer80: 'salon de manichiura',
                    answer81: 'spa',
                    answer82: 'salon de make up',
                    answer83: 'sala de sport , fitness',
                    answer84: 'masaj , relaxare',
                    answer85: 'altele',

                    domain15: 'Comert :',
                    answer86: 'magazin fizic sau ambulant – magazine mici care se aprovizioaneaza de la engross',
                    answer87: 'magazine mari, au propriile produse , se aprovizioaneaza de la producatori',
                    answer88: 'magazin online',
                    answer89: 'comert engross, en detail',
                    answer90: 'inamagazinarea , depozitarea',
                    answer91: 'alt tip de comert',

                    domain16: 'Constructii :',
                    answer92: 'Constructii',

                    domain17: 'Domeniul financiar / bancar :',
                    descriptionDomain17: '(afacerile în finanțe cuprind băncile si organizații ce obțin profit din gestionare capitalului)',
                    answer93: ' alte activitati',
                    answer94: 'banci',
                    answer95: 'leasing',
                    answer96: 'diverse societati de creditare',

                    domain18: 'Transport :',
                    answer97: 'transport rutier de mărfuri',
                    answer98: 'transport rutier de animale',
                    answer99: 'transport rutier de persoane',
                    answer100: 'transport rutier special',
                    answer101: 'Feroviar',
                    answer102: 'Naval , fluvial',
                    answer103: 'Prin conducte',
                    answer104: 'Aerian',

                    domain19: 'Distributie : externa / interna :',
                    answer105: 'Stocarea mărfurilor/ Depozitarea mărfurilor',
                    answer106: 'Manipularea fizică a mărfurilor',
                    answer107: 'Fluxurile informaţionale',
                    answer108: 'intermediar de distributie',
                    answer109: 'altele',

                    domain20: 'Turism, Administratie :',
                    answer110: 'agentii de turism, ghid turistic',
                    answer111: 'comunicare',
                    answer112: 'stiinte politice',
                    answer113: 'altele',

                    domain21: 'Securitate :',
                    answer114: 'detectivi',
                    answer115: 'servicii paza si protectie',
                    answer116: 'aparatura',


                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSix: {
                    question: '5. Interesul statului/comunitatii/administratiei militare pentru afacere:',
                    answer1: 'nu am furnizat servicii/produse catre autoritatile publice',
                    answer2: 'consider ca as putea sa ofer unele servicii/produse catre stat/administratie',
                    answer3: 'furnizez ocazional servicii/produse catre autoritatile publice',
                    answer4: 'furnizez frecvent servicii/produse catre stat sau autoritatile publice locale',
                    answer5: 'in urma unei situatii similare am furnizat servicii/produse autoritatilor publice (sau au fost interesati sa colaboram)',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSeven: {
                    question: '6. Experienta persoanei/persoanelor din conducere in domeniul de activitate:',

                    answer1: 'sub 1 an',
                    answer2: '1 – 3 ani',
                    answer3: '3 - 6 ani',
                    answer4: '6 - 12 ani',
                    answer5: 'peste 12 ani',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabEight: {
                    question: '7. Experienta conducerii in situatii similare(razboi/revolutii/ocupatie straina/etc.)',

                    answer1: 'nu am mai trecut printr-o situatie similara',
                    answer2: 'am mai trecut printr-o situatie similara',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabNine: {
                    question: '8. Experienta companiei in situatii similare(razboi/revolutii/ocupatie straina/etc.)',

                    answer1: 'nu am mai trecut printr-o situatie similara',
                    answer2: 'am mai trecut printr-o situatie similara',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabTen: {
                    question: '9. Capabilitatea de schimbare a obiectului de activitate in functie de cererea din piata .',

                    answer1: 'tipul de afacere nu permite schimbarea obiectului de activitate',
                    answer2: 'pana la 10 % din totalul afacerii',
                    answer3: 'pana la 30 % din totalul afacerii',
                    answer4: 'pana la 50 % din totalul afacerii',
                    answer5: 'pana la 70 % din totalul afacerii',
                    answer6: 'pana la 90 % din totalul afacerii',
                    answer7: ' 100 % totalul afacerii',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabEleven: {
                    question: '10. Datorii:',

                    answer1: ' nu sunt credite la banci, credite la furnizori peste 30 zile, leasing, altele',
                    answer2: 'sunt credite pe termen scurt (maxim 120 zile), sau care se pot achita in max 90 zile',
                    answer3: 'sunt credite la banci sau alte institutii finaciare, leasing, altele, care se pot achita in totalitate in maxim 6 luni – 3 pc',
                    answer4: 'sunt credite imobiliare, investitii, pe termen de peste 3 ani',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabTwelve: {
                    question: '11. Numarul de salariati:',

                    answer1: 'Intreprindere micro : <10 salariati',
                    answer2: 'Intreprindere mica : < 50 salariati',
                    answer3: 'Intreprindere mijlocie : < 250 salariati',
                    answer4: 'Intreprindere mare : + 250 salariati',
                    answer5: 'multinationala: + 500 salariati',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabThirteen: {
                    question: '12. Aria de activitate:',

                    answer1: 'Intreprinderi cu arie de activitate locala (un oras sau o zona din acesta,un sat)',
                    answer2: 'Intreprinderi cu arie de activitate regionala (o parte din tara, mai multe judete)',
                    answer3: 'Intreprinderi cu arie de activitate nationala',
                    answer4: 'Intreprinderi cu arie de activitate transnationala sau globala.',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabFourteen: {
                    question: '13. Active ce pot fi valorificate',

                    answer1: 'Concesiuni brevete, licenţe, mărci comerciale şi alte drepturi şi active similare',
                    answer2: 'Mijloace fixe si mobile ce pot fi valorificate, fara sa afecteze activitatea curenta',
                    answer3: 'Terenuri, constructii, Instalaţii tehnice, mijloace de transport, animale şi plantaţii',
                    answer4: 'Acţiuni tranzactionabile deţinute la diverse entităţi',
                    answer5: 'Împrumuturi acordate, cu posibilitate reala de retragere in 15 zile sau aducatoare de venit',
                    answer6: 'nu avem active ce pot fi valorificate imediat',
                    answer7: 'stoc suplimentar de marfuri/ materie prima, vandabile imediat',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                resultTab: {
                    title: 'Rezultat',
                    emailTitle: 'Rezultat Chestionar',
                    descriptionPart1: 'Ați obținut un punctaj de',
                    descriptionPart2: 'puncte!',
                    descriptionPart3: 'Totodată v-am trimis și un email cu rezultatul.',
                    rewardTitle: 'Calificativ',
                    reward1: 'Nesatisfacator',
                    reward2: 'Satisfacator',
                    reward3: 'Bine',
                    reward4: 'Foarte bine',
                    recomandationTitle: 'Recomandare',
                    recomandation1: 'Trebuie executate schimbari imediate in cadrul afacerii, revizuite amanuntitdetaliile si se vor lua decizii ce pot preveni situatii neplacute si asigura viitorul afacerii. Nu este foarte dificil, urmeaza pasii de mai jos:',
                    r11: 'Sa revenim la chestionar si la fiecare grupa de intrebari, observa in ce situatie puteti obtine punctaj mai mare. Realizeaza ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r12: 'Observa riscurile pe care le are activitatea ta. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa opresti sau sa fie afectata semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r13: 'Observa avantajele/punctele pozitive  pe care le are afacerea dumneavoastra. Vei incerca sa dezvoltati aceste puncte pozitive, fara a iti asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r14: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r15: 'Desi, observatiile de mai sus par simple, avand in vedere punctajul care s-a obtinut initial, vor avea un impact major in cadrul afacerii. Dar aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating mai bun in relatia cu institutiile finaciare.',
                    r16: 'Se fac modificarile in cadrul afacerii si se poate reveni, pentru o noua previziune.',
                    r1C: 'Totodata se poate solicita o analiza personalizata .',
                    recomandation2: 'Este potrivit sa se revizuiasca activitatea afacerii si sa aduci mici schimbari , pentru o previziune mai buna. Nu este foarte dificil, urmeaza pasii de mai jos :',
                    r21: 'Revenim la chestionar si la fiecare grupa de intrebari observa in ce situatie poti obtine punctaj mai mare. Vei realiza ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r22: 'Observa riscurile pe care le are activitatea. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa fie oprita sau sa afectata semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r23: 'Observa avantajele/punctele pozitive  pe care le are afacerea ta. Vei incerca sa dezvolti aceste puncte pozitive, fara a iti asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r24: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r25: 'Desi, observatiile de mai sus par simple, avand in vedere punctajul care a fost obtinut initial, vor avea un impact semnificativ in cadrul afacerii. Dar aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating mai bun in relatia cu institutiile finaciare.',
                    r2c: 'Se vor face modificarile in cadrul afacerii si se poate reveni, pentru o noua previziune.Totodata poti solicita o analiza personalizata .',
                    recomandation3: 'Afacerea ta este intr-o situatie de risc acceptabila, normala, in conditiile de analiza ale acestui Program. Nu trebuie sa iti faci griji, in timp mai poti  aduce imbunatatiri, cu atentia indreptata asupra detaliilor. Daca doresti sa obtii un punctaj FOARTE BINE , poti urma , urmati pasii de mai jos ',
                    r31: 'Revenim la chestionar si la fiecare grupa de intrebari observam in ce situatie poti obtine punctaj mai mare. Realizam ce trebuie sa faci pentru a creste acest punctaj, in cadrul companiei tale.',
                    r32: 'Observam riscurile pe care le are activitatea companiei. Aceste riscuri trebuie sa le reduci, in functie de posibilitati si de specificul activitatii, dar fara sa oprim sau sa afectam semnificativ cresterea activitatii. Trebuie mentinut un echilibru constant intre diminuarea riscurilor si dezvoltarea afacerii.',
                    r33: 'Observam avantajele/punctele pozitive  pe care le are afacerea. Vei incerca sa dezvoltati aceste puncte pozitive, fara a va asuma un risc prea mare , dar un risc controlat va fi probabil necesar, in aceasta situatie.',
                    r34: 'Fiecare modificare pozitiva a unui punct din formular, atrage un punctaj mai mare, totodata va atrage dupa sine modificari pozitive in cadrul afacerii, in ceea ce priveste riscul general.',
                    r35: 'Avand in vedere punctajul care la fost obtinut initial, schimbarile efectuate pentru calificativul FOARTE BINE , vor avea un impact moderat in cadrul afacerii. Aceste schimbari vor contribui la un viitor mai sigur al companiei si la un rating maxim in relatia cu institutiile finaciare.',
                    r3c: 'Dupa ce se fac  modificarile in cadrul afacerii se poate reveni, pentru o noua previziune. Totodata se poate solicita o analiza personalizata .',
                    recomandation4: 'Felicitari ! Afacerea ta este protejata de riscurile cuprinse in acest program. Succes pe mai departe ! Totodata puteti solicita o analiza personalizata .',
                    r41: 'A fost analizat riscul din toate punctele de vedere cunoscute in momentul actual, cuprinse in acest program de analiza, cu un istoric al riscului in afaceri din ultimii 100 de ani. ',
                    r42: 'Au fost luate in calcul o multitudine de domenii, dintre care amintim : Tipul de Afacere , Aria Geografica, Dimensiunea si Dezvoltarea Afacerilor,  Clima, Pregatire Profesionala, Domeniul Finaciar Contabil.',
                    buttonMenu: 'Meniu'
                }
            }
        },
        questionaryPandemie: {
            buyCodePage: {
                title: 'Vă rugăm să completați formularul de mai jos',
                firstName: 'Prenume',
                lastName: 'Nume',
                email: 'Email',
                next: 'Înainte',
                afterPayment: {
                    title: 'Mai jos aveți codul pentru chestionar. Vă rugăm să salvați preventiv codul în cazul în care nu primiți niciun email cu acesta.',
                    codeFor: 'Codul pentru',
                    questionary: 'chestionar',
                    error: 'Ne pare rău, dar ceva nu a mers bine!',
                    goToMenu: 'Mergi la meniu'
                }
            },
            questionaryPage: {
                error: 'Vă rugăm să alegeți un răspuns!',
                tabOnePresentation: {
                    title: 'Predictibilitatea afacerilor pentru profesionisti si companii in caz de pandemie .',
                    criterii: 'Criterii de verificare : puncte de la 1 la 100',
                    question: 'Ce dorim sa determinam ?',
                    answer: 'Capabilitatea clientului de a trece peste o situatie de pandemie , totodata punctele unde trebuie sa intervina pentru a reusi sa depaseasca cu succes un asemenea moment.',
                    pleaseAddCode: 'Vă rugăm să adăugați codul chestionarului',
                    next: 'Înainte'
                },
                tabTwo: {
                    question: '2. Lichiditati, rezerve (media lichiditatilor pe ultimele 12 luni, din actele contabile)',
                    domain1: 'Situatii (alege o singura varianta)',
                    answer1: 'sub 0,8 %',
                    answer2: 'intre 0,8 - 1 %',
                    answer3: 'intre 1 - 1,5 %',
                    answer4: 'intre 1,5 - 2 %',
                    answer5: 'intre 2 - 2,5 %',
                    answer6: 'peste 2,5 %',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabThree: {
                    question: '4. Tipul de afacere:',
                    description: 'Tipul de afacere se alege un obiect de activitate prepondrent . Daca situatia finala nu este multumitoare si aveti o activitate secundara , incercati sa introduceti aceasta activitate ca fiind principala si determinati care dintre activitati este mai eficienta .',

                    domain1: 'Explotarea resurselor naturale vegetale si animale :',
                    answer1: 'apicultura',
                    answer2: 'avicultura',
                    answer3: 'horticultura: Pomicultura, Viticultura, Oenologia, Legumicultura, Floricultura, Arhitectură peisagistică, Dendrologia',
                    answer4: 'sericicultura(cultura matasii)',
                    answer5: 'agricultura',
                    answer6: 'vanatoarea',
                    answer7: 'silvicultura',
                    answer8: 'exploatarea forestiera',
                    answer9: 'piscicultura',
                    answer10: 'acvacultura',

                    domain2: 'Industrii :',
                    answer11: 'industria constructiei de masini , aparate electrice',

                    domain3: 'Industria IT :',
                    answer12: 'cercetare dezvoltare',
                    answer13: 'informatica',
                    answer14: 'programare software / hardware',
                    answer15: 'retele de socializare',
                    answer16: 'aplicatii mobile',
                    answer17: 'web designer',
                    answer18: 'procesare',
                    answer19: 'limbaje de programare',
                    answer20: 'electronica',
                    answer21: 'alte activitati',

                    domain4: 'Productia industrială a resurselor naturale :',
                    answer22: 'industria hidrocarburilor - petrol , gaze naturale , carbuni',
                    answer23: 'extractia carbunilor , minereurilor',
                    answer24: 'productie materie prima',
                    answer25: 'industria cauciucului',
                    answer26: 'industria chimica / petrochimica',
                    answer27: 'industria energetica - energie solara , energie eoliana , hidrocentrale',
                    answer28: 'instrustria siderurgica / metalurgica',
                    answer29: 'industria materialelor pretioase',
                    answer30: 'alte resurse',

                    domain5: 'Industria Alimentara :',
                    answer31: 'alimente de baza : apa, paine, ulei, zahar',
                    answer32: 'alimente aditionale: dulciuri, sucuri',
                    answer33: 'productie marfuri de larg consum',
                    answer34: 'industria bauturilor alcoolice',
                    answer35: 'industria tutunului',
                    answer36: 'industria produselor lactate',
                    answer37: 'industria carnii',
                    answer38: 'industria legumelor, fructelor',
                    answer39: 'alte produse',

                    domain6: 'Industria Usoara :',
                    answer40: 'imbracaminte',
                    answer41: 'incaltaminte',
                    answer42: 'pielarie',
                    answer43: 'textile',
                    answer44: 'confectii',
                    answer45: 'hârtia și fabricarea articolelor din hârtie',
                    answer46: 'altele',
                    answer47: 'Industria materialelor de constructie :',
                    answer48: 'Productie utilaje',
                    answer49: 'Alte ramuri',

                    domain8: 'Servicii:',
                    answer50: 'servisare tehnica calcul',
                    answer51: 'service auto/moto',
                    answer52: 'service aparatura casnica',
                    answer53: 'service diverse',

                    domain9: 'Sanatate , invatamant, alimentatie:',
                    answer54: 'hoteluri , restaurante',
                    answer55: 'invatamant',
                    answer56: 'sanatate',

                    domain10: 'Telecomunicatii, audio, video :',
                    answer57: 'telefonie',
                    answer58: 'statii emisie- receptie',
                    answer59: 'televiziune',
                    answer60: 'cinematografe',
                    answer61: 'prelucrare audio, video',

                    domain11: 'Servicii imobiliare, contructii , inchirieri :',
                    answer62: 'design',
                    answer63: 'arhitectura',
                    answer64: 'proiectare',
                    answer65: 'tranzactii imobiliare',
                    answer66: 'inchirieri',
                    answer67: 'inchirieri echipament / masini',
                    answer68: 'inginerie',

                    domain12: 'Posta, Consultanta, Publicitate :',
                    answer69: 'consultanta',
                    answer70: 'publicitate',
                    answer71: 'altele',

                    domain13: 'Utilitati, mediu, deseuri :',
                    answer72: 'colectare, prelucrare, transport deseuri',
                    answer73: 'distributie apa',
                    answer74: 'energie electrica',
                    answer75: 'caldura',
                    answer76: 'reciclare',
                    answer77: 'intretinere si curatenie',
                    answer78: 'altele',

                    domain14: 'Infrumusetare si intretinere :',
                    answer79: 'frizerie – coafura',
                    answer80: 'salon de manichiura',
                    answer81: 'spa',
                    answer82: 'salon de make up',
                    answer83: 'sala de sport , fitness',
                    answer84: 'masaj , relaxare',
                    answer85: 'altele',

                    domain15: 'Comert :',
                    answer86: 'magazin fizic sau ambulant – magazine mici care se aprovizioaneaza de la engross',
                    answer87: 'magazine mari, au propriile produse , se aprovizioaneaza de la producatori',
                    answer88: 'magazin online',
                    answer89: 'comert engross, en detail',
                    answer90: 'inamagazinarea , depozitarea',
                    answer91: 'alt tip de comert',

                    domain16: 'Constructii :',
                    answer92: 'Constructii',

                    domain17: 'Domeniul financiar / bancar :',
                    descriptionDomain17: '(afacerile în finanțe cuprind băncile si organizații ce obțin profit din gestionare capitalului)',
                    answer93: 'alte activitati',
                    answer94: 'banci',
                    answer95: 'leasing',
                    answer96: 'diverse societati de creditare',

                    domain18: 'Transport :',
                    answer97: 'transport rutier de mărfuri',
                    answer98: 'transport rutier de animale',
                    answer99: 'transport rutier de persoane',
                    answer100: 'transport rutier special',
                    answer101: 'Feroviar',
                    answer102: 'Naval , fluvial',
                    answer103: 'Prin conducte',
                    answer104: 'Aerian',

                    domain19: 'Distributie : externa / interna :',
                    answer105: 'Stocarea mărfurilor/ Depozitarea mărfurilor',
                    answer106: 'Manipularea fizică a mărfurilor',
                    answer107: 'Fluxurile informaţionale',
                    answer108: 'intermediar de distributie',
                    answer109: 'altele',

                    domain20: 'Turism, Administratie :',
                    answer110: 'agentii de turism, ghid turistic',
                    answer111: 'comunicare',
                    answer112: 'stiinte politice',
                    answer113: 'altele',

                    domain21: 'Securitate :',
                    answer114: 'detectivi',
                    answer115: 'servicii paza si protectie',
                    answer116: 'aparatura',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFour: {
                    question: '3. Interesul statului/comunitatii/administratiei sanitare pentru afacere:',
                    answer1: 'nu am furnizat servicii/produse catre autoritatile publice',
                    answer2: 'consider ca as putea sa ofer unele servicii/produse catre stat/administratie',
                    answer3: 'furnizez ocazional servicii/produse catre autoritatile publice',
                    answer4: 'furnizez frecvent servicii/produse catre stat sau autoritatile publice locale',
                    answer5: 'in urma unei situatii similare am furnizat servicii/produse autoritatilor publice (sau au fost interesati sa colaboram)',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFive: {
                    question: '4. Experienta persoanei/persoanelor din conducere in domeniul de activitate:',

                    answer1: 'sub 1 an',
                    answer2: '1 – 3 ani',
                    answer3: '3 - 6 ani',
                    answer4: '6 - 12 ani',
                    answer5: 'peste 12 ani',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabSix: {
                    question: '5. Experienta conducerii in situatii similare(criza financiara/recesiune economica)',

                    answer1: 'nu am mai trecut printr-o situatie similara',
                    answer2: 'am mai trecut printr-o situatie similara',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabSeven: {
                    question: '6. Capabilitatea de schimbare a obiectului de activitate in functie de cererea din piata',

                    answer1: 'tipul de afacere nu permite schimbarea obiectului de activitate',
                    answer2: 'pana la 10 % din totalul afacerii',
                    answer3: 'pana la 30 % din totalul afacerii',
                    answer4: 'pana la 50 % din totalul afacerii',
                    answer5: 'pana la 70 % din totalul afacerii',
                    answer6: 'pana la 90 % din totalul afacerii',
                    answer7: ' 100 % totalul afacerii',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabEight: {
                    question: '7. Datoriile companiei',
                    answer1: 'nu sunt credite la banci, credite la furnizori peste 30 zile, leasing, altele',
                    answer2: 'sunt credite pe termen scurt (maxim 120 zile), sau care se pot achita in max 90 zile',
                    answer3: 'sunt credite la banci sau alte institutii finaciare, leasing, altele, care se pot achita in totalitate in maxim 6 luni',
                    answer4: 'sunt credite imobiliare, investitii, pe termen de peste 3 ani',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabNine: {
                    question: '8. Posibilitatea ca angajatii sa lucreze de acasa:',
                    answer1: 'angajatii companiei nu pot lucra de acasa',
                    answer2: 'angajatii pot lucra de acasa',
                    answer3: 'angajatii pot lucra partial de acasa',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
            }
        },
        questionaryCrizaFinanciara: {
            buyCodePage: {
                title: 'Vă rugăm să completați formularul de mai jos',
                firstName: 'Prenume',
                lastName: 'Nume',
                email: 'Email',
                next: 'Înainte',
                afterPayment: {
                    title: 'Mai jos aveți codul pentru chestionar. Vă rugăm să salvați preventiv codul în cazul în care nu primiți niciun email cu acesta.',
                    codeFor: 'Codul pentru',
                    questionary: 'chestionar',
                    error: 'Ne pare rău, dar ceva nu a mers bine!',
                    goToMenu: 'Mergi la meniu'
                }
            },
            questionaryPage: {
                tabOnePresentation: {
                    title: 'Eficienta companiei in situatii de recesiune, criza finaciara',
                    criterii: 'Criterii de verificare : puncte de la 1 la 100',
                    question: 'Ce dorim sa determinam ?',
                    answer: 'Caracteristici : gandire pe termen scurt , afecteaza situatia financiara/ banii si nu afecteaza efectiv bunurile, reduce valoarea bunurilor care nu sunt de utilitate imediata',
                    pleaseAddCode: 'Vă rugăm să adăugați codul chestionarului',
                    next: 'Înainte'
                },
                tabTwo: {
                    question: '1. Vechimea',
                    answer1: 'sub 1 an',
                    answer2: '1-3 ani',
                    answer3: '3-5 ani',
                    answer4: '5-15 ani',
                    answer5: '15- 30 ani',
                    answer6: 'peste 30 ani',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabThree: {
                    question: '2. Lichiditati, rezerve (media lichiditatilor in banca/ casa pe ultimul an )',
                    answer1: 'sub 1 %',
                    answer2: 'intre 1 - 3 %',
                    answer3: 'intre 3 - 5 %',
                    answer4: 'intre 5 - 10 %',
                    answer5: 'peste 10 %',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFour: {
                    question: '3. Marimea companiei:',
                    answer1: 'cifra de afaceri anuala pana in 120.000 Euro',
                    answer2: 'cifra de afaceri anuala intre 120.000 - 500.000 Euro',
                    answer3: 'cifra de afaceri anuala intre 500.000 - 1.000.000 Euro',
                    answer4: 'cifra de afaceri anuala intre 1.000.000 - 5.000.000 Euro',
                    answer5: 'cifra de afaceri anuala intre 5.000.000 - 50.000.000 Euro',
                    answer6: 'cifra de afaceri anuala intre 50.000.000 - 150.000.000 Euro',
                    answer7: 'cifra de afaceri anuala peste 150.000.000 Euro',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabFive: {
                    question: '4. Tipul de afacere:',
                    description: 'Tipul de afacere se alege un obiect de activitate prepondrent . Daca situatia finala nu este multumitoare si aveti o activitate secundara , incercati sa introduceti aceasta activitate ca fiind principala si determinati care dintre activitati este mai eficienta .',

                    domain1: 'Explotarea resurselor naturale vegetale si animale :',
                    answer1: 'apicultura',
                    answer2: 'avicultura',
                    answer3: 'horticultura: Pomicultura, Viticultura, Oenologia, Legumicultura, Floricultura, Arhitectură peisagistică, Dendrologia',
                    answer4: 'sericicultura(cultura matasii)',
                    answer5: 'agricultura',
                    answer6: 'vanatoarea',
                    answer7: 'silvicultura',
                    answer8: 'exploatarea forestiera',
                    answer9: 'piscicultura',
                    answer10: 'acvacultura',

                    domain2: 'Industrii :',
                    answer11: 'industria constructiei de masini , aparate electrice',

                    domain3: 'Industria IT :',
                    answer12: 'cercetare dezvoltare',
                    answer13: 'informatica',
                    answer14: 'programare software / hardware',
                    answer15: 'retele de socializare',
                    answer16: 'aplicatii mobile',
                    answer17: 'web designer',
                    answer18: 'procesare',
                    answer19: 'limbaje de programare',
                    answer20: 'electronica',
                    answer21: 'alte activitati',

                    domain4: 'Productia industrială a resurselor naturale :',
                    answer22: 'industria hidrocarburilor - petrol , gaze naturale , carbuni',
                    answer23: 'extractia carbunilor , minereurilor',
                    answer24: 'productie materie prima',
                    answer25: 'industria cauciucului',
                    answer26: 'industria chimica / petrochimica',
                    answer27: 'industria energetica - energie solara , energie eoliana , hidrocentrale',
                    answer28: 'instrustria siderurgica / metalurgica',
                    answer29: 'industria materialelor pretioase',
                    answer30: 'alte resurse',

                    domain5: 'Industria Alimentara :',
                    answer31: 'alimente de baza : apa, paine, ulei, zahar',
                    answer32: 'alimente aditionale: dulciuri, sucuri',
                    answer33: 'productie marfuri de larg consum',
                    answer34: 'industria bauturilor alcoolice',
                    answer35: 'industria tutunului',
                    answer36: 'industria produselor lactate',
                    answer37: 'industria carnii',
                    answer38: 'industria legumelor, fructelor',
                    answer39: 'alte produse',

                    domain6: 'Industria Usoara :',
                    answer40: 'imbracaminte',
                    answer41: 'incaltaminte',
                    answer42: 'pielarie',
                    answer43: 'textile',
                    answer44: 'confectii',
                    answer45: 'hârtia și fabricarea articolelor din hârtie',
                    answer46: 'Industria materialelor de constructie :',
                    answer47: 'Productie utilaje',
                    answer48: 'Alte ramuri',

                    domain8: 'Servicii:',
                    answer49: 'servisare tehnica calcul',
                    answer50: 'service auto/moto',
                    answer51: 'service aparatura casnica',
                    answer52: 'service diverse',

                    domain9: 'Sanatate , invatamant, alimentatie:',
                    answer53: 'hoteluri , restaurante',
                    answer54: 'invatamant',
                    answer55: 'sanatate',

                    domain10: 'Telecomunicatii, audio, video :',
                    answer56: 'telefonie',
                    answer57: 'statii emisie- receptie',
                    answer58: 'televiziune',
                    answer59: 'cinematografe',
                    answer60: 'prelucrare audio, video',

                    domain11: 'Servicii imobiliare, contructii , inchirieri :',
                    answer61: 'design',
                    answer62: 'arhitectura',
                    answer63: 'proiectare',
                    answer64: 'tranzactii imobiliare',
                    answer65: 'inchirieri',
                    answer66: 'inchirieri echipament / masini',
                    answer67: 'inginerie',

                    domain12: 'Posta, Consultanta, Publicitate :',
                    answer68: 'consultanta',
                    answer69: 'publicitate',
                    answer70: 'altele',

                    domain13: 'Utilitati, mediu, deseuri :',
                    answer71: 'colectare, prelucrare, transport deseuri',
                    answer72: 'distributie apa',
                    answer73: 'energie electrica',
                    answer74: 'caldura',
                    answer75: 'reciclare',
                    answer76: 'intretinere si curatenie',
                    answer77: 'servicii sanitare',
                    answer78: 'altele',

                    domain14: 'Infrumusetare si intretinere :',
                    answer79: 'frizerie – coafura',
                    answer80: 'salon de manichiura',
                    answer81: 'spa',
                    answer82: 'salon de make up',
                    answer83: 'sala de sport , fitness',
                    answer84: 'masaj , relaxare',
                    answer85: 'altele',

                    domain15: 'Comert :',
                    answer86: 'magazin fizic sau ambulant – magazine mici care se aprovizioaneaza de la engross',
                    answer87: 'magazine mari, au propriile produse , se aprovizioaneaza de la producatori',
                    answer88: 'magazin online',
                    answer89: 'comert engross, en detail',
                    answer90: 'inamagazinarea , depozitarea',
                    answer91: 'alt tip de comert',

                    domain16: 'Constructii :',
                    answer92: 'Constructii',

                    domain17: 'Domeniul financiar / bancar :',
                    descriptionDomain17: '(afacerile în finanțe cuprind băncile si organizații ce obțin profit din gestionare capitalului)',
                    answer93: ' alte activitati',
                    answer94: 'banci',
                    answer95: 'leasing',
                    answer96: 'diverse societati de creditare',

                    domain18: 'Transport :',
                    answer97: 'transport rutier de mărfuri',
                    answer98: 'transport rutier de animale',
                    answer99: 'transport rutier de persoane',
                    answer100: 'transport rutier special',
                    answer101: 'Feroviar',
                    answer102: 'Naval , fluvial',
                    answer103: 'Prin conducte',
                    answer104: 'Aerian',

                    domain19: 'Distributie : externa / interna :',
                    answer105: 'Stocarea mărfurilor/ Depozitarea mărfurilor',
                    answer106: 'Manipularea fizică a mărfurilor',
                    answer107: 'Fluxurile informaţionale',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSix: {
                    question: '5. Interesul statului/comunitatii pentru afacere:',
                    answer1: 'furnizez frecvent servicii/produse catre stat sau autoritatile publice locale',
                    answer2: 'compania dispune de produse sau servicii care ar putea fi achizitionate de catre structurile statului',
                    answer3: 'compania poate sa isi restructureze productia /serviciile in termen de 30 zile , cu produse/servicii solicitate de structurile statului',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabSeven: {
                    question: '6. Experienta persoanei/persoanelor din conducere in domeniul de activitate:',

                    answer1: 'sub 1 an',
                    answer2: '1 – 3 ani',
                    answer3: '3 - 6 ani',
                    answer4: '6 - 12 ani',
                    answer5: 'peste 12 ani',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabEight: {
                    question: '7. Experienta conducerii in situatii similare(criza financiara/recesiune economica)',

                    answer1: 'am mai trecut printr-o criza finaciara sau printr-o recesiune economica',
                    answer2: 'activitatea s-a mentinut la acelasi nivel',
                    answer3: 'activitatea a scazut cu 1-10 %',
                    answer4: 'activitatea a scazut cu 11-20 %',
                    answer5: 'activitatea ascazut cu 21-30',
                    answer6: 'activitatea a scazut cu 31-45',
                    answer7: 'activitatea a scazut peste 46 %',
                    answer8: 'activitatea a crescu',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabNine: {
                    question: '8. Experienta companiei in situatii similare(criza financiara/recesiune economica)',
                    domain1: 'a mai trecut printr-o criza finaciara',
                    answer1: 'DA',
                    answer2: 'NU',
                    domain2: 'a mai trecut printr-o recesiune economica',
                    answer3: 'DA',
                    answer4: 'NU',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabTen: {
                    question: '9. Capabilitatea de schimbare a obiectului de activitate in functie de cererea din piata .',

                    answer1: 'tipul de afacere nu permite schimbarea obiectului de activitate',
                    answer2: 'pana la 10 % din totalul afacerii',
                    answer3: 'pana la 30 % din totalul afacerii',
                    answer4: 'pana la 50 % din totalul afacerii',
                    answer5: 'pana la 70 % din totalul afacerii',
                    answer6: 'pana la 90 % din totalul afacerii',
                    answer7: ' 100 % totalul afacerii',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabEleven: {
                    question: '10. Datoriile:',
                    answer1: 'nu sunt credite la banci, credite la furnizori peste 30 zile, leasing, altele',
                    answer2: 'Credite pe termen scurt (maxim 90 zile), sau care se pot achita in max 90 zile',
                    answer3: 'Credite la banci sau alte institutii finaciare, leasing, altele care se achita in totalitate in maxim 6 luni',
                    actions: {
                        back: 'Înapoi',
                        forward: 'Înainte'
                    }
                },
                tabTwelve: {
                    question: '11. Numarul de salariati:',

                    answer1: 'Intreprindere micro : <10 salariati',
                    answer2: 'Intreprindere mica : < 50 salariati',
                    answer3: 'Intreprindere mijlocie : < 250 salariati',
                    answer4: 'Intreprindere mare : + 250 salariati',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabThirteen: {
                    question: '12. Aria de activitate:',

                    answer1: 'Intreprinderi cu arie de activitate locala (un oras sau o zona din acesta,un sat)',
                    answer2: 'Intreprinderi cu arie de activitate regionala (o parte din tara, mai multe judete)',
                    answer3: 'Intreprinderi cu arie de activitate nationala',
                    answer4: 'Intreprinderi cu arie de activitate transnationala sau globala.',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
                tabFourteen: {
                    question: '13. Active ce pot fi valorificate',

                    answer1: 'Concesiuni brevete, licenţe, mărci comerciale şi alte drepturi şi active similare',
                    answer2: 'Mijloace fixe si mobile ce pot fi valorificate, fara sa afecteze activitatea curenta',
                    answer3: 'Terenuri, constructii, Instalaţii tehnice, mijloace de transport, animale şi plantaţii',
                    answer4: 'Acţiuni tranzactionabile deţinute la diverse entităţi',
                    answer5: 'Împrumuturi acordate, cu posibilitate reala de retragere in 15 zile sau aducatoare de venit ',
                    answer6: 'stoc suplimentar de marfuri/ materie prima, vandabile imediat',
                    answer7: 'compania nu se afla in situatiile de mai sus',

                    actions: {
                        back: 'Înapoi',
                        forward: 'Finalizează'
                    }
                },
            }
        },
    },
    {
        id: 'en',
        homePage: {
            title: 'About  Predict-On',
            content1: 'PREDICT-on is a data analysis and calculation program, to give you a perspective on the situation of your company in case of economic crises, war, natural disasters or pandemics. Basically, it gives you a clearer picture of the current situation and possible solutions for the future.',
            content2: 'This program is based on data collection and information, studies and history analysis, for the last 100 years, in most fields of activity.',
            content3: 'In 2018, the idea was launched and research began. A team of young people collected data and information, and experienced managers prepared a pattern of predictability, based on economic and historical data of each field of activity. In the end, the Predict program resulted.',
            buttonAnalizaPersonalizata: 'Requests Custom Analysis / Contact',
            buttonBuyFullPredict: 'Buy Full Predict',
            buttonFullPredictResult: 'Full Predict results',
            free: 'Free',
            questionaryCards: {
                calamnitati: {
                    title: 'NATURAL DISASTERS',
                    description: 'Fires, Floods, Droughts, Storms, Earthquakes, Landslides, Volcyearsc Eruptions, other risks will be analyzed for you. You need to know ! The result on the spot!',
                    buttonBuyCode: 'Buy a code',
                    buttonUseCode: 'Use the code'
                },
                riscRazboi: {
                    title: 'RISK IN A WAR SITUATION',
                    description: 'Does an armed conflict seem far from you? History says otherwise. How prepared is your business in case of war? Find out in 5 minutes!',
                    buttonBuyCode: 'Buy a code',
                    buttonUseCode: 'Use the code'
                },
                pandemie: {
                    title: 'PANDEMIC',
                    description: 'With the evolution of mankind, new viruses and diseases have appeared periodically. Pandemics are a current risk. How will you proceed? Are you ready for this? Complete the form !',
                    buttonBuyCode: 'Buy a code',
                    buttonUseCode: 'Use the code'
                },
                crizaFinanciara: {
                    title: 'THE FINANCIAL CRISIS',
                    description: 'Every 10 years there is an economic recession. Every 30 years there is a major economic crisis. Are you ready to face it? Consult Predict and you will find out in 5 minutes!',
                    buttonBuyCode: 'Buy a code',
                    buttonUseCode: 'Use the code'
                }
            },
            analizeModal: {
                title: 'Please fill out the form below',
                firstName: 'First name',
                lastName: 'Name',
                email: 'Email',
                cancel: 'Cancel',
                send: 'Send',
                fullPredictBuyContent: 'Complete Analysis! Buy now Full Predict and you will have all 4 analysis programs, for a complete prediction! You will find out the real situation of your company for all the risks provided by Predict-On.',
                fullPredictInstructions: 'Below are the codes for the questionnaires. Please save the codes in advance if you do not receive any emails with them.',
                codeFor: 'Code for',
                error: 'Sorry, but something went wrong!',
                goToMenu: 'Go to the menu'
            }
        },
        fullPredictResultPage: {
            questionaryResultLabel: 'Questionnaire result',
            points: 'points',
            total: 'Total',
            din: 'of',
            posiblePoints: 'possible points',
            result: {
                label: 'Result',
                valueOne: 'Unsatisfactory',
                valueTwo: 'Acceptable',
                valueThree: 'Good',
                valueFour: 'Very good',
            },
            barem: {
                label: 'Standart',
                valueOne: 'up to 119 points inclusive',
                valueTwo: 'between 120 - 219 points',
                valueThree: 'between 220 - 349 points',
                valueFour: 'between 350 - 400 points',
            },
            buttonMenu: 'Menu'
        },
        questionaryCalamnitatiPage: {
            buyCodePage: {
                title: 'Please fill out the form below',
                firstName: 'First name',
                lastName: 'Name',
                email: 'Email',
                next: 'Forward',
                afterPayment: {
                    title: 'Below is the code for the questionnaire. Please save the code in advance if you do not receive an email with it.',
                    codeFor: 'The code for',
                    questionary: 'questionnaire',
                    error: 'Sorry, but something went wrong!',
                    goToMenu: 'Go to the menu'
                }
            },
            questionaryPage: {
                error: 'Please choose an answer!',
                tabOnePresentation: {
                    title: `The company's efficiency in situations of natural disasters.`,
                    criterii: 'Verification criteria: points from 1 to 100',
                    pleaseAddCode: 'Please add the questionnaire code',
                    next: 'Forward'
                },
                tabTwo: {
                    question: '1. Seismic risk area (choose only one option)',
                    answer1: 'there have been earthquakes in the last 10 years',
                    answer2: 'there were earthquakes 10-30 years ago',
                    answer3: 'there were earthquakes 30-50 years ago',
                    answer4: 'there were earthquakes 50-100 years ago',
                    answer5: 'there were earthquakes 100-500 years ago',
                    answer6: 'there is a seismic alert point / system in the area',
                    answer7: 'no history of earthquakes in the area is known',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabThree: {
                    question: '2. Flood risk area',
                    domain1: 'Situations (choose only one option)',
                    answer1: 'there have been no floods in the geographical area in the last 5 years',
                    answer2: 'no flood situations are known in the past',
                    answer3: 'there have been no floods in the geographical area in the last 20 years',
    
                    domain2: 'Prevent',
                    answer4: 'there were floods in the area and serious measures were taken to prevent it',
                    answer12: 'I do not know if there have been floods in the area and if serious measures have been taken to prevent them',
    
                    domain3: 'Location (choose only one option)',
                    answer5: 'location near running water',
                    answer6: 'location near a lake',
                    answer7: 'location on the seafront',
                    answer8: 'oceanfront location',
                    answer9: 'location at the base of a mountain',
                    answer10: 'location on a slope, or at the foot of a hill',
                    answer11: 'location in the area where there can be no risk of flooding',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFour: {
                    question: '3. Landslide risk area (choose only one option)',
                    answer1: 'there have been landslides in the last 10 years',
                    answer2: 'there were landslides 10-30 years ago',
                    answer3: 'there were landslides 30-50 years ago',
                    answer4: 'there were landslides 50-100 years ago',
                    answer5: 'there were landslides 100-500 years ago',
                    answer6: 'I have no knowledge of landslides',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFive: {
                    question: '4. Risk of Thunderstorms, Strong Winds (choose only one option)',
                    answer1: 'there are no storms in the area',
                    answer2: 'there have been no significant storms in the last 5 years',
                    answer3: 'the previous storms are of intensity: small',
                    answer4: 'previous storms are of intensity: medium',
                    answer5: 'previous storms are of intensity: high',
                    answer6: 'there are strong storms and winds every year',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSix: {
                    question: '5. Risk of Drought (accumulation of points)',
                    answer1: 'we do not notice an increase in air temperature in the hot season, in the last 20 years',
                    answer2: 'in the last 5 years there has not been a lower volume of precipitation, compared to the last 20 years',
                    answer3: 'there have been no vegetation fires in the last 3 years',
                    answer4: 'there are no changes in the structure of precipitation, compared to last year (the amount of precipitation is approximately in the same volume and in the same calendar period) if you are in an area where it snows, the amount of snow last winter was not less than last year previous',
                    answer5: 'if you are in a snowy area, the amount of snow last winter was not less than in the previous year',
                    answer6: 'in the previous year there was no drought',
                    answer7: 'over an area of ​​50 km, the area of ​​forests / high vegetation has not been significantly reduced in the last 20 years',
                    answer8: 'nu am observat o scadere semnificativa a nivelului apei, in rau, lac, fantana, in yearsi precedenti',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSeven: {
                    question: '6. Fires (accumulation of points)',
                    domain1: 'About the geographical area',
                    answer1: 'are in an area where there have been no vegetation fires in the last 5 years',
                    answer2: 'there are no high temperatures in the area in the warm season',
                    answer3: 'there is no drought in the area in the hot season',
                    domain2: 'About my company',
                    answer4: 'have fire protection system, including alarm',
                    answer5: 'have a fire risk insurance policy',
                    answer6: 'on an area of ​​20 m there is a fire hydrant',
                    answer7: 'have water source on the property',
                    answer8: 'Flammable materials were also used in the construction of my buildings, but less than 20% of the total construction',
                    answer9: 'the building has lightning rods',
                    answer10: 'the building has protection at the fuse panel',
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                resultTab: {
                    title: 'Result',
                    emailTitle: 'Questionnaire result',
                    descriptionPart1: 'You got a score of',
                    descriptionPart2: 'points!',
                    descriptionPart3: 'I also sent you an email with the result.',
                    rewardTitle: 'Qualifying',
                    reward1: 'Unsatisfactory',
                    reward2: 'Satisfactorily',
                    reward3: 'Good',
                    reward4: 'Very good',
                    recomandationTitle: 'Recommendation',
                    recomandation1: 'Immediate changes in the business must be made, the details must be reviewed in detail and decisions will be made that can prevent unpleasant situations and ensure the future of the business. It is not very difficult, follow the steps below:',
                    r11: 'Let s go back to the questionnaire and to each group of questions, notice in which situation you can get a higher score. Realize what you need to do to increase this score in your company.',
                    r12: 'Observe the risks of your activity. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without stopping or being significantly affected by the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r13: 'Notice the advantages / positive points that your business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r14: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r15: 'Although, the above observations seem simple, given the score that was initially obtained, will have a major impact on the business. But these changes will contribute to a safer future for the company and a better rating in relation to financial institutions.',
                    r16: 'Changes are made in the business and you can come back for a new forecast.',
                    r1C: 'At the same time, a personalized analysis can be requested.',
                    recomandation2: 'It is appropriate to review business activity and make small changes for better forecasting. It is not very difficult, follow the steps below:',
                    r21: 'We return to the questionnaire and observe each group of questions in which situation you can get a higher score. You will realize what you need to do to increase this score, within your company.',
                    r22: 'Observe the risks of the activity. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without being stopped or significantly affecting the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r23: 'Notice the advantages / positive points that your business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r24: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r25: 'Although, the above observations seem simple, given the score that was initially obtained, they will have a significant impact on the business. But these changes will contribute to a safer future for the company and a better rating in relation to financial institutions.',
                    r2c: 'Changes will be made within the business and you can come back for a new forecast. You can also request a customized analysis.',
                    recomandation3: 'Your business is in an acceptable, normal risk situation, under the conditions of analysis of this Program. You don t have to worry, over time you can make improvements, with attention to detail. If you want to get a VERY GOOD score, you can follow, follow the steps below',
                    r31: 'We return to the questionnaire and in each group of questions we notice in which situation you can get a higher score. We realize what you need to do to increase this score, within your company.',
                    r32: 'We notice the risks that the company s activity has. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without stopping or significantly affecting the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r33: 'We notice the advantages / positive points that the business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r34: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r35: 'Considering the score that was initially obtained, the changes made for the VERY GOOD rating will have a moderate impact on the business. These changes will contribute to a more secure future for the company and a maximum rating in relation to financial institutions.',
                    r3c: 'After the changes are made in the business, you can come back for a new forecast. At the same time, a personalized analysis can be requested.',
                    recomandation4: 'Congratulations ! Your business is protected from the risks contained in this program. Good luck! You can also request a customized analysis.',
                    r41: 'The risk was analyzed from all currently known points of view, included in this analysis program, with a history of business risk from the last 100 years.',
                    r42: 'A multitude of fields were taken into account, among which we mention: Business Type, Geographical Area, Size and Business Development, Climate, Professional Training, Financial Accounting Domain.',
                    buttonMenu: 'Menu'
                }
            }
        },
        questionaryRazboi: {
            buyCodePage: {
                title: 'Please fill out the form below',
                firstName: 'First name',
                lastName: 'Name',
                email: 'Email',
                next: 'Forward',
                afterPayment: {
                    title: 'Below is the code for the questionnaire. Please save the code in advance if you do not receive an email with it.',
                    codeFor: 'The code for',
                    questionary: 'questionnaire',
                    error: 'Sorry, but something went wrong!',
                    goToMenu: 'Go to the menu'
                }
            },
            questionaryPage: {
                error: 'Please choose an answer!',
                tabOnePresentation: {
                    title: 'Predictability of business in case of war, for professionals and compyearses.',
                    criterii: 'Verification criteria: points from 1 to 100',
                    question: 'What do we want to determine?',
                    answer: 'The client s ability to get over a war situation (armed conflict, revolutions, foreign occupation, etc.), as well as the points where he must intervene in order to successfully overcome such a moment.',
                    pleaseAddCode: 'Please add the questionnaire code',
                    next: 'Forward'
                },
                tabTwo: {
                    question: '1. Age',
                    answer1: 'under 1 year',
                    answer2: '1-3 years',
                    answer3: '3-5 years',
                    answer4: '5-15 years',
                    answer5: '15-30 years',
                    answer6: 'over 30 years',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabThree: {
                    question: '2. Liquidity, reserves (average liquidity for the last 12 months, from the accounting documents)',
                    domain1: 'Situations (choose only one option)',
                    answer1: 'less than 0.8%',
                    answer2: 'between 0.8 - 1%',
                    answer3: 'between 1 - 1.5%',
                    answer4: 'between 1.5 - 2%',
                    answer5: 'between 1.5 - 2%',
                    answer6: 'over 2.5%',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFour: {
                    question: '3. Company size:',
                    answer1: 'annual turnover up to 120,000 Euro',
                    answer2: 'annual turnover between 120,000 - 500,000 Euro',
                    answer3: 'annual turnover between 500,000 - 1,000,000 Euro',
                    answer4: 'annual turnover between 1,000,000 - 5,000,000 Euro',
                    answer5: 'annual turnover between 5.000.000 - 50.000.000 Euro',
                    answer6: 'annual turnover between 50.000.000 - 150.000.000 Euro',
                    answer7: 'annual turnover over 150,000,000 Euros',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFive: {
                    question: '4. Type of business:',
                    description: 'The type of business is chosen as a preponderant object of activity. If the final situation is not satisfactory and you have a secondary activity, try to introduce this activity as the main one and determine which of the activities is more efficient.',
    
                    domain1: 'Exploitation of natural plant and yearsmal resources:',
                    answer1: 'beekeeping',
                    answer2: 'Poultry',
                    answer3: 'horticulture: Pomiculture, Viticulture, Oenology, Vegetable growing, Floriculture, Landscape architecture, Dendrology',
                    answer4: 'sericulture (culture silasii)',
                    answer5: 'agriculture',
                    answer6: 'Hunting',
                    answer7: 'forestry',
                    answer8: 'logging',
                    answer9: 'pisciculture',
                    answer10: 'aquaculture',
    
                    domain2: 'Industries:',
                    answer11: 'machine building industry, electrical appliances',
    
                    domain3: 'IT industry:',
                    answer12: 'Research and Development',
                    answer13: 'Informatics',
                    answer14: 'software / hardware programming',
                    answer15: 'social networks',
                    answer16: 'mobile applications',
                    answer17: 'web designer',
                    answer18: 'processing',
                    answer19: 'programming languages',
                    answer20: 'electronic',
                    answer21: 'other activities',
    
                    domain4: 'Industrial production of natural resources:',
                    answer22: 'hydrocarbon industry - oil, natural gas, coal',
                    answer23: 'extraction of coal, ores',
                    answer24: 'raw material production',
                    answer25: 'rubber industry',
                    answer26: 'chemical / petrochemical industry',
                    answer27: 'energy industry - solar energy, wind energy, hydropower',
                    answer28: 'steel / metallurgical industry',
                    answer29: 'precious materials industry',
                    answer30: 'other resources',
    
                    domain5: 'Food industry :',
                    answer31: 'staple foods: water, bread, oil, sugar',
                    answer32: 'Additional foods: sweets, juices',
                    answer33: 'production of consumer goods',
                    answer34: 'alcoholic beverage industry',
                    answer35: 'tobacco industry',
                    answer36: 'dairy industry',
                    answer37: 'meat industry',
                    answer38: 'vegetable and fruit industry',
                    answer39: 'other products',
    
                    domain6: 'Light industry :',
                    answer40: 'clothing',
                    answer41: 'footwear',
                    answer42: 'leather',
                    answer43: 'text',
                    answer44: 'clothing',
                    answer45: 'paper and manufacture of articles of paper',
                    answer46: 'more',
    
                    domain7: 'Construction materials industry:',
                    answer47: 'Equipment production',
                    answer48: 'Other branches',
    
                    domain8: 'Services:',
                    answer49: 'calculation technical servicing',
                    answer50: 'car / motorcycle service',
                    answer51: 'home appliance service',
                    answer52: 'various service',
    
                    domain9: 'Health, education, nutrition:',
                    answer53: 'hotels, restaurants',
                    answer54: 'education',
                    answer55: 'health',
    
                    domain10: 'Telecommunications, audio, video:',
                    answer56: 'telephony',
                    answer57: 'transmission-reception stations',
                    answer58: 'television',
                    answer59: 'movie theaters',
                    answer60: 'movie theaters',
    
                    domain11: 'Real estate services, constructions, rentals:',
                    answer61: 'design',
                    answer62: 'architecture',
                    answer63: 'projection',
                    answer64: 'real estate transactions',
                    answer65: 'Rent',
                    answer66: 'equipment / car rentals',
                    answer67: 'engineering',
    
                    domain12: 'Post, Consulting, Advertising:',
                    answer68: 'consultant',
                    answer69: 'advertisement',
                    answer70: 'more',
    
                    domain13: 'Utilities, environment, waste:',
                    answer71: 'waste collection, processing, transport',
                    answer72: 'distributie apa',
                    answer73: 'energie electrica',
                    answer74: 'heat',
                    answer75: 'recycle',
                    answer76: 'maintenance and cleyearsng',
                    answer77: 'health services',
                    answer78: 'more',
    
                    domain14: 'Beauty and maintenance:',
                    answer79: 'barber shop - hairstyle',
                    answer80: 'myearscure salon',
                    answer81: 'spa',
                    answer82: 'make up salon',
                    answer83: 'gym, fitness',
                    answer84: 'massage, relaxation',
                    answer85: 'more',
    
                    domain15: 'Trade :',
                    answer86: 'physical or mobile store - small stores that supply wholesale',
                    answer87: 'Large stores, have their own products, are sourced from manufacturers',
                    answer88: 'online shop',
                    answer89: 'wholesale, retail',
                    answer90: 'storage, warehousing',
                    answer91: 'other type of trade',
    
                    domain16: 'Constructions:',
                    answer92: 'construction',
    
                    domain17: 'Financial / banking field:',
                    descriptionDomain17: '(business in finance includes banks and orgyearszations that make a profit from capital management)',
                    answer93: 'other activities',
                    answer94: 'banks',
                    answer95: 'leasing',
                    answer96: 'various lending compyearses',
    
                    domain18: 'Transport :',
                    answer97: 'road transport of goods',
                    answer98: 'road transport of yearsmals',
                    answer99: 'road transport of people',
                    answer100: 'special road transport',
                    answer101: 'Railway',
                    answer102: 'Naval, fluvial',
                    answer103: 'Through the pipes',
                    answer104: 'Aerial',
    
                    domain19: 'Distribution: external / internal:',
                    answer105: 'Storage of goods / Storage of goods',
                    answer106: 'Physical handling of goods',
                    answer107: 'Information flows',
                    answer108: 'distribution intermediary',
                    answer109: 'more',
    
                    domain20: 'Tourism, Administration:',
                    answer110: 'travel agencies, tourist guide',
                    answer111: 'communication',
                    answer112: 'political Sciences',
                    answer113: 'more',
    
                    domain21: 'Security: ',
                    answer114: 'detectives',
                    answer115: 'security and protection services',
                    answer116: 'devices',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSix: {
                    question: '5. The interest of the state / community / military administration for the business:',
                    answer1: 'we did not provide services / products to public authorities',
                    answer2: 'I consider that I could offer some services / products to the state / administration',
                    answer3: 'I occasionally provide services / products to public authorities',
                    answer4: 'I frequently provide services / products to the state or local public authorities',
                    answer5: 'following a similar situation we provided services / products to public authorities (or were interested in collaborating)',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSeven: {
                    question: '6. Experience of the person / persons in management in the field of activity:',
    
                    answer1: 'under 1 year',
                    answer2: '1 – 3 years',
                    answer3: '3 - 6 years',
                    answer4: '6 - 12 years',
                    answer5: 'over 12 years',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabEight: {
                    question: '7. Leadership experience in similar situations (war / revolutions / foreign occupation / etc.)',
    
                    answer1: 'I have never been in a similar situation',
                    answer2: 'I went through a similar situation',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabNine: {
                    question: '8. The company s experience in similar situations(razboi/revolutii/ocupatie straina/etc.)',
    
                    answer1: 'I have never been in a similar situation',
                    answer2: 'I went through a similar situation',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabTen: {
                    question: '9. Ability to change the object of activity according to market demand.',
    
                    answer1: 'the type of business does not allow the change of the object of activity',
                    answer2: 'up to 10% of the total business',
                    answer3: 'up to 30% of the total business',
                    answer4: 'up to 50% of the total business',
                    answer5: 'up to 70% of the total business',
                    answer6: 'up to 90% of the total business',
                    answer7: ' 100% total business',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabEleven: {
                    question: '10. Debts:',
    
                    answer1: 'there are no loans to banks, loans to suppliers over 30 days, leasing, more',
                    answer2: 'are short-term loans (maximum 120 days), or that can be paid in max 90 days',
                    answer3: 'are loans to banks or other financial institutions, leasing, more, which can be paid in full in maximum 6 months ',
                    answer4: 'are real estate loans, investments, for a term of over 3 years',
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabTwelve: {
                    question: '11. Number of employees:',
    
                    answer1: 'Micro enterprise: <10 employees',
                    answer2: 'Small enterprise: <50 employees',
                    answer3: 'Medium enterprise: <250 employees',
                    answer4: 'Large enterprise: + 250 employees',
                    answer5: 'multinational: + 500 employees',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabThirteen: {
                    question: '12. Area of activity:',
    
                    answer1: 'Enterprises with local area of activity (a city or an area of it, a village)',
                    answer2: 'Enterprises with regional activity area (part of the country, several counties)',
                    answer3: 'Enterprises with national activity area',
                    answer4: 'Enterprises with transnational or global activity area.',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabFourteen: {
                    question: '13. Assets that can be capitalized',
    
                    answer1: 'Patent grants, licenses, trademarks and other similar rights and assets',
                    answer2: 'fixed and mobile assets that can be capitalized, without affecting the current activity',
                    answer3: 'Land, construction, technical installations, means of transport, yearsmale and plantations',
                    answer4: 'Marketable shares held in various entities',
                    answer5: 'Loans granted, with real possibility of withdrawal in 15 days or income',
                    answer6: 'we do not have assets that can be capitalized immediately',
                    answer7: 'additional stock of goods / raw materials, salable immediately',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                resultTab: {
                    title: 'Result',
                    emailTitle: 'Questionnaire result',
                    descriptionPart1: 'You got a score of',
                    descriptionPart2: 'points!',
                    descriptionPart3: 'I also sent you an email with the result.',
                    rewardTitle: 'Qualifying',
                    reward1: 'Unsatisfactory',
                    reward2: 'Satisfactorily',
                    reward3: 'Good',
                    reward4: 'Very good',
                    recomandationTitle: 'Recommendation',
                    recomandation1: 'Immediate changes in the business must be made, the details must be reviewed in detail and decisions will be made that can prevent unpleasant situations and ensure the future of the business. It is not very difficult, follow the steps below:',
                    r11: 'Let s go back to the questionnaire and to each group of questions, notice in which situation you can get a higher score. Realize what you need to do to increase this score in your company.',
                    r12: 'Observe the risks of your activity. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without stopping or being significantly affected by the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r13: 'Notice the advantages / positive points that your business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r14: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r15: 'Although, the above observations seem simple, given the score that was initially obtained, will have a major impact on the business. But these changes will contribute to a safer future for the company and a better rating in relation to financial institutions.',
                    r16: 'Changes are made in the business and you can come back for a new forecast.',
                    r1C: 'At the same time, a personalized analysis can be requested.',
                    recomandation2: 'It is appropriate to review business activity and make small changes for better forecasting. It is not very difficult, follow the steps below:',
                    r21: 'We return to the questionnaire and observe each group of questions in which situation you can get a higher score. You will realize what you need to do to increase this score, within your company.',
                    r22: 'Observe the risks of the activity. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without being stopped or significantly affecting the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r23: 'Notice the advantages / positive points that your business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r24: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r25: 'Although, the above observations seem simple, given the score that was initially obtained, they will have a significant impact on the business. But these changes will contribute to a safer future for the company and a better rating in relation to financial institutions.',
                    r2c: 'Changes will be made within the business and you can come back for a new forecast. You can also request a customized analysis.',
                    recomandation3: 'Your business is in an acceptable, normal risk situation, under the conditions of analysis of this Program. You don t have to worry, over time you can make improvements, with attention to detail. If you want to get a VERY GOOD score, you can follow, follow the steps below',
                    r31: 'We return to the questionnaire and in each group of questions we notice in which situation you can get a higher score. We realize what you need to do to increase this score, within your company.',
                    r32: 'We notice the risks that the company s activity has. These risks must be reduced, depending on the possibilities and the specifics of the activity, but without stopping or significantly affecting the growth of the activity. A constant balance must be maintained between risk reduction and business development.',
                    r33: 'We notice the advantages / positive points that the business has. You will try to develop these positive points without taking too much risk, but a controlled risk will probably be necessary in this situation.',
                    r34: 'Each positive change of a point in the form, attracts a higher score, it will also lead to positive changes in the business, in terms of overall risk.',
                    r35: 'Considering the score that was initially obtained, the changes made for the VERY GOOD rating will have a moderate impact on the business. These changes will contribute to a more secure future for the company and a maximum rating in relation to financial institutions.',
                    r3c: 'After the changes are made in the business, you can come back for a new forecast. At the same time, a personalized analysis can be requested.',
                    recomandation4: 'Congratulations ! Your business is protected from the risks contained in this program. Good luck! You can also request a customized analysis.',
                    r41: 'The risk was analyzed from all currently known points of view, included in this analysis program, with a history of business risk from the last 100 years.',
                    r42: 'A multitude of fields were taken into account, among which we mention: Business Type, Geographical Area, Size and Business Development, Climate, Professional Training, Financial Accounting Domain.',
                    buttonMenu: 'Menu'
                }
            }
        },
        questionaryPandemie: {
            buyCodePage: {
                title: 'Please fill out the form below',
                firstName: 'First name',
                lastName: 'Name',
                email: 'Email',
                next: 'Forward',
                afterPayment: {
                    title: 'Below is the code for the questionnaire. Please save the code in advance if you do not receive an email with it.',
                    codeFor: 'The code for',
                    questionary: 'questionnaire',
                    error: 'Sorry, but something went wrong!',
                    goToMenu: 'Go to the menu'
                }
            },
            questionaryPage: {
                error: 'Please choose an answer!',
                tabOnePresentation: {
                    title: 'Predictability of business for professionals and compyears in case of pandemic.',
                    criterii: 'Verification criteria: points from 1 to 100',
                    question: 'What do we want to determine?',
                    answer: 'The client s ability to overcome a pandemic situation, as well as the points where he must intervene in order to successfully overcome such a moment.',
                    pleaseAddCode: 'Please add the questionnaire code',
                    next: 'Forward'
                },
                tabTwo: {
                    question: '2. Lichiditati, rezerve (media lichiditatilor pe ultimele 12 luni, din actele contabile)',
                    domain1: 'Situations (choose only one option)',
                    answer1: 'less than 0.8%',
                    answer2: 'between 0.8 - 1%',
                    answer3: 'between 1 - 1.5%',
                    answer4: 'between 1.5 - 2%',
                    answer5: 'between 1.5 - 2%',
                    answer6: 'over 2.5%',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabThree: {
                    question: '4. Business type:',
                    description: 'The type of business is chosen as a preponderant object of activity. If the final situation is not satisfactory and you have a secondary activity, try to introduce this activity as the main one and determine which of the activities is more efficient.',
    
                    domain1: 'Exploitation of natural plant and yearsmal resources:',
                    answer1: 'beekeeping',
                    answer2: 'Poultry',
                    answer3: 'horticulture: Pomiculture, Viticulture, Oenology, Vegetable growing, Floriculture, Landscape architecture, Dendrology',
                    answer4: 'sericulture (culture silasii)',
                    answer5: 'agriculture',
                    answer6: 'Hunting',
                    answer7: 'forestry',
                    answer8: 'logging',
                    answer9: 'pisciculture',
                    answer10: 'aquaculture',
    
                    domain2: 'Industries:',
                    answer11: 'machine building industry, electrical appliances',
    
                    domain3: 'IT industry:',
                    answer12: 'Research and Development',
                    answer13: 'Informatics',
                    answer14: 'software / hardware programming',
                    answer15: 'social networks',
                    answer16: 'mobile applications',
                    answer17: 'web designer',
                    answer18: 'processing',
                    answer19: 'programming languages',
                    answer20: 'electronic',
                    answer21: 'other activities',
    
                    domain4: 'Industrial production of natural resources:',
                    answer22: 'hydrocarbon industry - oil, natural gas, coal',
                    answer23: 'extraction of coal, ores',
                    answer24: 'raw material production',
                    answer25: 'rubber industryi',
                    answer26: 'chemical / petrochemical industry',
                    answer27: 'energy industry - solar energy, wind energy, hydropower',
                    answer28: 'steel / metallurgical industry',
                    answer29: 'precious materials industry',
                    answer30: 'other resources',
    
                    domain5: 'Food industry :',
                    answer31: 'staple foods: water, bread, oil, sugar',
                    answer32: 'Additional foods: sweets, juices',
                    answer33: 'production of consumer goods',
                    answer34: 'alcoholic beverage industry',
                    answer35: 'tobacco industry',
                    answer36: 'dairy industry',
                    answer37: 'meat industry',
                    answer38: 'vegetable and fruit industry',
                    answer39: 'other products',
    
                    domain6: 'Light industry :',
                    answer40: 'clothing',
                    answer41: 'footwear',
                    answer42: 'leather',
                    answer43: 'text',
                    answer44: 'clothing',
                    answer45: 'paper and manufacture of articles of paper',
                    answer46: 'more',
                    answer47: 'Construction materials industry:',
                    answer48: 'Equipment production',
                    answer49: 'Other branches',
    
                    domain8: 'Services:',
                    answer50: 'calculation technical servicing',
                    answer51: 'car / motorcycle service',
                    answer52: 'home appliance service',
                    answer53: 'various service',
    
                    domain9: 'Health, education, nutrition:',
                    answer54: 'hotels, restaurants',
                    answer55: 'education',
                    answer56: 'health',
    
                    domain10: 'Telecommunications, audio, video:',
                    answer57: 'telephony',
                    answer58: 'transmission-reception stations',
                    answer59: 'television',
                    answer60: 'movie theaters',
                    answer61: 'movie theaters',
    
                    domain11: 'Real estate services, constructions, rentals:',
                    answer62: 'design',
                    answer63: 'architecture',
                    answer64: 'projection',
                    answer65: 'real estate transactions',
                    answer66: 'Rent',
                    answer67: 'equipment / car rentals',
                    answer68: 'engineering',
    
                    domain12: 'Post, Consulting, Advertising:',
                    answer69: 'consultant',
                    answer70: 'advertisement',
                    answer71: 'more',
    
                    domain13: 'Utilities, environment, waste:',
                    answer72: 'waste collection, processing, transport',
                    answer73: 'distributie apa',
                    answer74: 'energie electrica',
                    answer75: 'heat',
                    answer76: 'recycle',
                    answer77: 'maintenance and cleyearsng',
                    answer78: 'more',
    
                    domain14: 'Beauty and maintenance:',
                    answer79: 'barber shop - hairstyle',
                    answer80: 'myearscure salon',
                    answer81: 'spa',
                    answer82: 'make up salon',
                    answer83: 'gym, fitness',
                    answer84: 'massage, relaxation',
                    answer85: 'more',
    
                    domain15: 'Trade :',
                    answer86: 'physical or mobile store - small stores that supply wholesale',
                    answer87: 'Large stores, have their own products, are sourced from manufacturers',
                    answer88: 'online shop',
                    answer89: 'wholesale, retail',
                    answer90: 'storage, warehousing',
                    answer91: 'other type of trade',
    
                    domain16: 'Constructions:',
                    answer92: 'construction',
    
                    domain17: 'Financial / banking field:',
                    descriptionDomain17: '(business in finance includes banks and organizations that make a profit from capital management)',
                    answer93: 'other activities',
                    answer94: 'banks',
                    answer95: 'leasing',
                    answer96: 'various lending compyearses',
    
                    domain18: 'Transport :',
                    answer97: 'road transport of goods',
                    answer98: 'road transport of yearsmals',
                    answer99: 'road transport of people',
                    answer100: 'special road transport',
                    answer101: 'Railway',
                    answer102: 'Naval, fluvial',
                    answer103: 'Through the pipes',
                    answer104: 'Aerial',
    
                    domain19: 'Distribution: external / internal:',
                    answer105: 'Storage of goods / Storage of goods',
                    answer106: 'Physical handling of goods',
                    answer107: 'Information flows',
                    answer108: 'distribution intermediary',
                    answer109: 'more',
    
                    domain20: 'Tourism, Administration:',
                    answer110: 'travel agencies, tourist guide',
                    answer111: 'communication',
                    answer112: 'political Sciences',
                    answer113: 'more',
    
                    domain21: 'Security: ',
                    answer114: 'detectives',
                    answer115: 'security and protection services',
                    answer116: 'devices',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFour: {
                    question: '3. The interest of the state / community / state administration for the business:',
                    answer1: 'we did not provide services / products to public authorities',
                    answer2: 'I consider that I could offer some services / products to the state / administration',
                    answer3: 'I occasionally provide services / products to public authorities',
                    answer4: 'I frequently provide services / products to the state or local public authorities',
                    answer5: 'following a similar situation we provided services / products to public authorities (or were interested in collaborating)',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFive: {
                    question: '4. Experience of the person / persons in management in the field of activity:',
    
                    answer1: 'under 1 year',
                    answer2: '1 – 3 years',
                    answer3: '3 - 6 years',
                    answer4: '6 - 12 years',
                    answer5: 'over 12 years',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabSix: {
                    question: '5. Management experience in similar situations (financial crisis / economic recession)',
    
                    answer1: 'I have never been in a similar situation',
                    answer2: 'I went through a similar situation',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabSeven: {
                    question: '6. Ability to change the object of activity according to market demand',
    
                    answer1: 'the type of business does not allow the change of the object of activity',
                    answer2: 'up to 10% of the total business',
                    answer3: 'up to 30% of the total business',
                    answer4: 'up to 50% of the total business',
                    answer5: 'up to 70% of the total business',
                    answer6: 'up to 90% of the total business',
                    answer7: ' 100% total business',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabEight: {
                    question: '7. Company debts',
                    answer1: 'there are no loans to banks, loans to suppliers over 30 days, leasing, more',
                    answer2: 'are short-term loans (maximum 120 days), or that can be paid in max 90 days',
                    answer3: 'are loans to banks or other financial institutions, leasing, more, which can be paid in full in maximum 6 months ',
                    answer4: 'are real estate loans, investments, for a term of over 3 years',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabNine: {
                    question: '8. Possibility for employees to work from home:',
                    answer1: 'the company s employees cannot work from home',
                    answer2: 'employees can work from home',
                    answer3: 'employees can partially work from home',
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
            }
        },
        questionaryCrizaFinanciara: {
            buyCodePage: {
                title: 'Please fill out the form below',
                firstName: 'First name',
                lastName: 'Name',
                email: 'Email',
                next: 'Forward',
                afterPayment: {
                    title: 'Below is the code for the questionnaire. Please save the code in advance if you do not receive an email with it.',
                    codeFor: 'The code for',
                    questionary: 'questionnaire',
                    error: 'Sorry, but something went wrong!',
                    goToMenu: 'Go to the menu'
                }
            },
            questionaryPage: {
                tabOnePresentation: {
                    title: 'The company s efficiency in recession situations, financial crisis',
                    criterii: 'Verification criteria: points from 1 to 100',
                    question: 'What do we want to determine?',
                    answer: 'Features: short-term thinking, affects the financial situation and does not actually affect goods, reduces the value of goods that are not of immediate use',
                    pleaseAddCode: 'Please add the questionnaire code',
                    next: 'Forward'
                },
                tabTwo: {
                    question: '1. Age',
                    answer1: 'under 1 year',
                    answer2: '1-3 years',
                    answer3: '3-5 years',
                    answer4: '5-15 years',
                    answer5: '15-30 years',
                    answer6: 'over 30 years',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabThree: {
                    question: '2. Liquidity, reserves (average liquidity in the bank / house for the last year)',
                    answer1: 'less than 1%',
                    answer2: 'between 1 - 3 %',
                    answer3: 'between 3 - 5 %',
                    answer4: 'between 5 - 10 %',
                    answer5: 'over 10 %',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFour: {
                    question: '3. Company size:',
                    answer1: 'annual turnover up to 120,000 Euro',
                    answer2: 'annual turnover between 120,000 - 500,000 Euro',
                    answer3: 'annual turnover between 500,000 - 1,000,000 Euro',
                    answer4: 'annual turnover between 1,000,000 - 5,000,000 Euro',
                    answer5: 'annual turnover between 5.000.000 - 50.000.000 Euro',
                    answer6: 'annual turnover between 50.000.000 - 150.000.000 Euro',
                    answer7: 'annual turnover over 150,000,000 Euros',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabFive: {
                    question: '4. Business type:',
                    description: 'The type of business is chosen as a preponderant object of activity. If the final situation is not satisfactory and you have a secondary activity, try to introduce this activity as the main one and determine which of the activities is more efficient.',
    
                    domain1: 'Exploitation of natural plant and yearsmal resources:',
                    answer1: 'beekeeping',
                    answer2: 'Poultry',
                    answer3: 'horticulture: Pomiculture, Viticulture, Oenology, Vegetable growing, Floriculture, Landscape architecture, Dendrology',
                    answer4: 'sericulture (culture silasii)',
                    answer5: 'agriculture',
                    answer6: 'Hunting',
                    answer7: 'forestry',
                    answer8: 'logging',
                    answer9: 'pisciculture',
                    answer10: 'aquaculture',
    
                    domain2: 'Industries:',
                    answer11: 'machine building industry, electrical appliances',
    
                    domain3: 'IT industry:',
                    answer12: 'Research and Development',
                    answer13: 'Informatics',
                    answer14: 'software / hardware programming',
                    answer15: 'social networks',
                    answer16: 'mobile applications',
                    answer17: 'web designer',
                    answer18: 'processing',
                    answer19: 'programming languages',
                    answer20: 'electronic',
                    answer21: 'other activities',
    
                    domain4: 'Industrial production of natural resources:',
                    answer22: 'hydrocarbon industry - oil, natural gas, coal',
                    answer23: 'extraction of coal, ores',
                    answer24: 'raw material production',
                    answer25: 'rubber industryi',
                    answer26: 'chemical / petrochemical industry',
                    answer27: 'energy industry - solar energy, wind energy, hydropower',
                    answer28: 'steel / metallurgical industry',
                    answer29: 'precious materials industry',
                    answer30: 'other resources',
    
                    domain5: 'Food industry :',
                    answer31: 'staple foods: water, bread, oil, sugar',
                    answer32: 'Additional foods: sweets, juices',
                    answer33: 'production of consumer goods',
                    answer34: 'alcoholic beverage industry',
                    answer35: 'tobacco industry',
                    answer36: 'dairy industry',
                    answer37: 'meat industry',
                    answer38: 'vegetable and fruit industry',
                    answer39: 'other products',
    
                    domain6: 'Light industry :',
                    answer40: 'clothing',
                    answer41: 'footwear',
                    answer42: 'leather',
                    answer43: 'text',
                    answer44: 'clothing',
                    answer45: 'paper and manufacture of articles of paper',
                    answer46: 'Construction materials industry:',
                    answer47: 'Equipment production',
                    answer48: 'Other branches',
    
                    domain8: 'Services:',
                    answer49: 'calculation technical servicing',
                    answer50: 'car / motorcycle service',
                    answer51: 'home appliance service',
                    answer52: 'various service',
    
                    domain9: 'Health, education, nutrition:',
                    answer53: 'hotels, restaurants',
                    answer54: 'education',
                    answer55: 'health',
    
                    domain10: 'Telecommunications, audio, video:',
                    answer56: 'telephony',
                    answer57: 'transmission-reception stations',
                    answer58: 'television',
                    answer59: 'movie theaters',
                    answer60: 'movie theaters',
    
                    domain11: 'Real estate services, constructions, rentals:',
                    answer61: 'design',
                    answer62: 'architecture',
                    answer63: 'projection',
                    answer64: 'real estate transactions',
                    answer65: 'Rent',
                    answer66: 'equipment / car rentals',
                    answer67: 'engineering',
    
                    domain12: 'Post, Consulting, Advertising:',
                    answer68: 'consultant',
                    answer69: 'advertisement',
                    answer70: 'more',
    
                    domain13: 'Utilities, environment, waste:',
                    answer71: 'waste collection, processing, transport',
                    answer72: 'distributie apa',
                    answer73: 'energie electrica',
                    answer74: 'heat',
                    answer75: 'recycle',
                    answer76: 'maintenance and cleyearsng',
                    answer77: 'health services',
                    answer78: 'more',
    
                    domain14: 'Beauty and maintenance:',
                    answer79: 'barber shop - hairstyle',
                    answer80: 'myearscure salon',
                    answer81: 'spa',
                    answer82: 'make up salon',
                    answer83: 'gym, fitness',
                    answer84: 'massage, relaxation',
                    answer85: 'more',
    
                    domain15: 'Trade :',
                    answer86: 'physical or mobile store - small stores that supply wholesale',
                    answer87: 'Large stores, have their own products, are sourced from manufacturers',
                    answer88: 'online shop',
                    answer89: 'wholesale, retail',
                    answer90: 'storage, warehousing',
                    answer91: 'other type of trade',
    
                    domain16: 'Constructions:',
                    answer92: 'construction',
    
                    domain17: 'Financial / banking field:',
                    descriptionDomain17: '(business in finance includes banks and organizations that make a profit from capital management)',
                    answer93: ' other activities',
                    answer94: 'banks',
                    answer95: 'leasing',
                    answer96: 'various lending compyearses',
    
                    domain18: 'Transport :',
                    answer97: 'road transport of goods',
                    answer98: 'road transport of yearsmals',
                    answer99: 'road transport of people',
                    answer100: 'special road transport',
                    answer101: 'Railway',
                    answer102: 'Naval, fluvial',
                    answer103: 'Through the pipes',
                    answer104: 'Aerial',
    
                    domain19: 'Distribution: external / internal:',
                    answer105: 'Storage of goods / Storage of goods',
                    answer106: 'Physical handling of goods',
                    answer107: 'Information flows',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSix: {
                    question: '5. Interesul statului/comunitatii pentru afacere:',
                    answer1: 'I frequently provide services / products to the state or local public authorities',
                    answer2: 'the company has products or services that could be purchased by state structures',
                    answer3: 'the company can restructure its production / services within 30 days, with products / services requested by state structures',
    
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabSeven: {
                    question: '6. Experience of the person / persons in management in the field of activity:',
    
                    answer1: 'under 1 year',
                    answer2: '1 – 3 years',
                    answer3: '3 - 6 years',
                    answer4: '6 - 12 years',
                    answer5: 'over 12 years',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabEight: {
                    question: '7. Management experience in similar situations (financial crisis / economic recession)',
    
                    answer1: 'I have gone through a financial crisis or an economic recession',
                    answer2: 'the activity remained at the same level',
                    answer3: 'activity decreased by 1-10%',
                    answer4: 'activity decreased by 11-20 %',
                    answer5: 'activity decreased by 21-30',
                    answer6: 'activity decreased by 31-45',
                    answer7: 'activity decreased by 46 %',
                    answer8: 'activity has increased',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabNine: {
                    question: '8. Company experience in similar situations (financial crisis / economic recession)',
                    domain1: 'he also went through a financial crisis',
                    answer1: 'yes',
                    answer2: 'no',
                    domain2: 'it has also gone through an economic recession',
                    answer3: 'yes',
                    answer4: 'no',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabTen: {
                    question: '9. Ability to change the object of activity according to market demand.',
    
                    answer1: 'the type of business does not allow the change of the object of activity',
                    answer2: 'up to 10% of the total business',
                    answer3: 'up to 30% of the total business',
                    answer4: 'up to 50% of the total business',
                    answer5: 'up to 70% of the total business',
                    answer6: 'up to 90% of the total business',
                    answer7: ' 100% total business',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabEleven: {
                    question: '10. Debts:',
                    answer1: 'there are no loans to banks, loans to suppliers over 30 days, leasing, more',
                    answer2: 'Short-term loans (maximum 90 days), or which can be paid in max 90 days',
                    answer3: 'Loans to banks or other financial institutions, leasing, more that are paid in full in a maximum of 6 months',
                    actions: {
                        back: 'Back',
                        forward: 'Forward'
                    }
                },
                tabTwelve: {
                    question: '11. Number of employees:',
    
                    answer1: 'Micro enterprise: <10 employees',
                    answer2: 'Small enterprise: <50 employees',
                    answer3: 'Medium enterprise: <250 employees',
                    answer4: 'Large enterprise: + 250 employees',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabThirteen: {
                    question: '12. Area of activity:',
    
                    answer1: 'Enterprises with local area of activity (a city or an area of it, a village)',
                    answer2: 'Enterprises with regional activity area (part of the country, several counties)',
                    answer3: 'Enterprises with national activity area',
                    answer4: 'Enterprises with transnational or global activity area.',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
                tabFourteen: {
                    question: '13. Assets that can be capitalized',
    
                    answer1: 'Patent grants, licenses, trademarks and other similar rights and assets',
                    answer2: 'fixed and mobile assets that can be capitalized, without affecting the current activity',
                    answer3: 'Land, construction, technical installations, means of transport, yearsmale and plantations',
                    answer4: 'Marketable shares held in various entities',
                    answer5: 'Loans granted, with real possibility of withdrawal in 15 days or income ',
                    answer6: 'additional stock of goods / raw materials, salable immediately',
                    answer7: 'the company is not in the above situations',
    
                    actions: {
                        back: 'Back',
                        forward: 'Complete'
                    }
                },
            }
        },
    }
]

export default languages