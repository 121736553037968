import React, { useState, useCallback, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Card, CardContent, CardActions, Button, Stack, Modal, TextField, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import ImageCalamnitati from '../../assets/calamnitati.jpeg';
import ImageFinanciar from '../../assets/financiar.jpeg';
import ImagePandemie from '../../assets/pandemie.jpeg';
import ImageRazboi from '../../assets/razboi.jpeg';
import ImageLogo from '../../assets/logo.jpeg';
import ImageWikipedia from '../../assets/wikipedia.png';
import ImageTikTok from '../../assets/tiktok.png';

import languages from './languages'
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';

const useStyles = makeStyles({
    gridContainer: {
        background: '#5840BB',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        minHeight: '102vh',
        padding: '0 30px',
        fontFamily: 'Nunito',
    },
    leftGrid: {
        background: '#5840BB',
        minHeight: '102vh'
    },
    leftGridContent: {
        position: 'relative',
    },
    leftGridBox: {
        marginLeft: '30px'
    },
    leftGridTitle: {
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '30px',
        width: '90%',
        textAlign: 'left'
    },
    leftGridText: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        width: '90%',
        fontSize: '20px',
        textAlign: 'left'
    },
    leftGridBtns: {
        width: '90%',
        marginTop: '40px',
        display: 'flex',
    },
    rightGrid: {
        background: '#5840BB',
        height: 'auto',
    },
    card: {
        minHeight: '420px',
        width: '100%',
        marginTop: '10px',
        gap: '10px'
    },
    calamnitati: {
        width: '100%',
        height: '200px',
        backgroundImage: `url(${ImageCalamnitati})`,
        backgroundSize: 'cover'
    },
    financiar: {
        width: '100%',
        height: '200px',
        backgroundImage: `url(${ImageFinanciar})`,
        backgroundSize: 'cover'
    },
    pandemie: {
        width: '100%',
        height: '200px',
        backgroundImage: `url(${ImagePandemie})`,
        backgroundSize: 'cover'
    },
    razboi: {
        width: '100%',
        height: '200px',
        backgroundImage: `url(${ImageRazboi})`,
        backgroundSize: 'cover'
    },
    logo: {
        width: '100px',
        height: '100px',
        margin: '50px',
        backgroundImage: `url(${ImageLogo})`,
        backgroundSize: '100%'
    },
    cardActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        textDecoration: 'none'
    },
    textField: {
        width: '300px'
    },
    footer: {
        left: 0,
        bottom: 0,
        right: 0,
        background: 'white',
        minHeight: 50,
        textAlign: 'center',
        lineHeight: '20px',
        paddingTop: 20
    },
    footerContainerIcon: {
        "&:hover": {
            cursor: "pointer",
        }
    },
    wikipedia: {
        width: '30px',
        height: '30px',
        marginTop: '4px',
        backgroundImage: `url(${ImageWikipedia})`,
        backgroundSize: 'cover',
        "&:hover": {
            cursor: "pointer",
        }
    },
    tiktok: {
        width: '30px',
        height: '30px',
        marginTop: '2px',
        backgroundImage: `url(${ImageTikTok})`,
        backgroundSize: 'cover',
        "&:hover": {
            cursor: "pointer",
        }
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Home() {
    const [language, setLanguage] = useState(null)
    const [languageCode, setLanguageCode] = useState(null)
    const [open, setOpen] = useState(false);
    const [userBody, setUserBody] = useState({
        firstName: '',
        lastName: '',
        email: '',
    })

    const handleChange = (event) => {
        if (!event?.target?.value) return
        setLanguageCode(event?.target?.value)
    };

    useEffect(() => {
        if (!localStorage.getItem('languageCode')) {
            localStorage.setItem('languageCode', 'ro')
            setLanguageCode('ro')
        } else {
            setLanguageCode(localStorage.getItem('languageCode'))
        }
    }, [])

    useEffect(() => {
        if (!languages?.length || !languageCode) return

        const selectedLanguage = languages.find(lang => lang?.id === languageCode)
        setLanguage(selectedLanguage)
        localStorage.setItem('languageCode', languageCode)
        localStorage.setItem('language_JSON', JSON.stringify(selectedLanguage))
        
    }, [languages, languageCode])

    const classes = useStyles()

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onSendRecomandationRequest = useCallback(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userBody)
        }
        fetch(window.baseURL + '/email/recommandation', requestOptions)
            .then(response => response.json())
            .then(body => {
                if (body) handleClose()
            })
    }, [userBody])


    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    
    if (!language) return

    return (
        <>
            <Box sx={{ flexGrow: 1 }} >
                <Grid container spacing={2} className={classes.gridContainer} direction="row-reverse">
                    <Grid xs={12} md={6} xl={7} className={classes.rightGrid}>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Select
                                style={{ minWidth: 150, background: 'white', marginTop: '20px' }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={languageCode}
                                label="Age"
                                onChange={handleChange}
                            >
                                {/* <MenuItem value='en'>English (EN)</MenuItem> */}
                                <MenuItem value='ro'>Română (RO)</MenuItem>
                                <MenuItem value='en'>English (EN)</MenuItem>
                                {/* <MenuItem value='es'>Espanol (ES)</MenuItem> */}
                            </Select>
                        </div>
                        <div className={classes.rightGridContent}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} xl={6}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {language.homePage.questionaryCards.calamnitati.title}
                                            </Typography>
                                            <div className={classes.calamnitati}></div>
                                            <br />
                                            <Typography color="text.secondary">
                                                {language.homePage.questionaryCards.calamnitati.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.cardActions}>
                                            <Stack spacing={1} direction="row">
                                                {/* <Link className={classes.link} to='/one-pay'> */}
                                                <Button disabled variant="outlined">{language.homePage.questionaryCards.calamnitati.buttonBuyCode}</Button>
                                                {/*</Link> */}
                                                <Link className={classes.link} to='/one'>
                                                    <Button variant="contained">{language.homePage.free}</Button>{/* {language.homePage.questionaryCards.calamnitati.buttonUseCode} */}
                                                </Link>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {language.homePage.questionaryCards.riscRazboi.title}
                                            </Typography>
                                            <div className={classes.razboi}></div>
                                            <br />
                                            <Typography color="text.secondary">
                                                {language.homePage.questionaryCards.riscRazboi.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.cardActions}>
                                            <Stack spacing={1} direction="row">
                                                {/* <Link className={classes.link} to='/two-pay'> */}
                                                <Button variant="outlined" disabled>{language.homePage.questionaryCards.calamnitati.buttonBuyCode}</Button>
                                                {/* </Link> */}
                                                <Link className={classes.link} to='/two'>
                                                    <Button variant="contained">{language.homePage.free}</Button>{/* {language.homePage.questionaryCards.calamnitati.buttonUseCode} */}
                                                </Link>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} xl={6}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {language.homePage.questionaryCards.pandemie.title}
                                            </Typography>
                                            <div className={classes.pandemie}></div>
                                            <br />
                                            <Typography color="text.secondary">
                                                {language.homePage.questionaryCards.pandemie.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.cardActions}>
                                            <Stack spacing={1} direction="row">
                                                <Link className={classes.link} to='/three-pay'>
                                                    <Button variant="outlined">{language.homePage.questionaryCards.calamnitati.buttonBuyCode}</Button>
                                                </Link>
                                                <Link className={classes.link} to='/three'>
                                                    <Button variant="contained">{language.homePage.questionaryCards.calamnitati.buttonUseCode}</Button>
                                                </Link>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {language.homePage.questionaryCards.crizaFinanciara.title}
                                            </Typography>
                                            <div className={classes.financiar}></div>
                                            <br />
                                            <Typography color="text.secondary">
                                                {language.homePage.questionaryCards.crizaFinanciara.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.cardActions}>
                                            <Stack spacing={1} direction="row">
                                                <Link className={classes.link} to='/four-pay'>
                                                    <Button variant="outlined">{language.homePage.questionaryCards.calamnitati.buttonBuyCode}</Button>
                                                </Link>
                                                <Link className={classes.link} to='/four'>
                                                    <Button variant="contained">{language.homePage.questionaryCards.calamnitati.buttonUseCode}</Button>
                                                </Link>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} xl={5} className={classes.leftGrid}>
                        <div className={classes.logo}></div>
                        <div className={classes.leftGridContent}>
                            <div className={classes.leftGridBox}>
                                <div className={classes.leftGridTitle}>
                                    <h3>{language.homePage.title}</h3>
                                </div>
                                <br />
                                <div className={classes.leftGridText}>
                                    {language.homePage.content1}
                                </div>
                                <br></br>
                                <div className={classes.leftGridText}>
                                    {language.homePage.content2}
                                </div>
                                <br></br>
                                <div className={classes.leftGridText}>
                                    {language.homePage.content3}
                                </div>
                                <div className={classes.leftGridBtns}>
                                    <Grid container >
                                        <Grid xs={24} md={24} xl={24} >
                                            <Button
                                                variant="contained"
                                                style={{
                                                    fontSize: '17px',
                                                    fontFamily: 'Montserrat',
                                                    fontWeight: '600',
                                                    background: 'white',
                                                    color: 'black',
                                                    border: 'black'
                                                }}
                                                onClick={() => handleOpen()}
                                            >
                                                {language.homePage.buttonAnalizaPersonalizata}
                                            </Button>
                                        </Grid>
                                        <Grid xs={24} md={24} xl={24} style={{ marginTop: 15 }}>
                                            <Link className={classes.link} to='/pay-full-predict'>
                                                <Button
                                                    variant="contained"
                                                    className={classes.leftGridBtn}
                                                    style={{
                                                        fontSize: '17px',
                                                        fontFamily: 'Montserrat',
                                                        fontWeight: '600',
                                                        background: 'green',
                                                        color: 'white',
                                                        border: 'black'
                                                    }}
                                                >
                                                    {language.homePage.buttonBuyFullPredict}
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid xs={24} md={24} xl={24} style={{ marginTop: 15 }}>
                                            <Link className={classes.link} to='/full-predict-result'>
                                                <Button
                                                    variant="contained"
                                                    className={classes.leftGridBtn}
                                                    style={{
                                                        fontSize: '17px',
                                                        fontFamily: 'Montserrat',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    {language.homePage.buttonFullPredictResult}
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div>
                            <h2>{language.homePage.analizeModal.title}</h2>
                            <TextField
                                style={{ width: '100%' }}
                                type='text'
                                label={language.homePage.analizeModal.firstName}
                                variant='outlined'
                                onChange={(e) => setUserBody({ ...userBody, firstName: e?.target?.value })}
                            />
                            <br />
                            <br />
                            <TextField
                                style={{ width: '100%' }}
                                type='text'
                                label={language.homePage.analizeModal.lastName}
                                variant='outlined'
                                onChange={(e) => setUserBody({ ...userBody, lastName: e?.target?.value })}
                            />
                            <br />
                            <br />
                            <TextField
                                style={{ width: '100%' }}
                                type='text'
                                label={language.homePage.analizeModal.email}
                                variant='outlined'
                                onChange={(e) => setUserBody({ ...userBody, email: e?.target?.value })}
                            />
                            <br />
                            <br />
                            <Stack spacing={1} direction="row">
                                <Button
                                    variant="outlined"
                                    onClick={() => handleClose()}
                                >
                                    {language.homePage.analizeModal.cancel}
                                </Button>
                                <Button
                                    disabled={!userBody.firstName || !userBody.lastName || !userBody.email}
                                    variant='contained'
                                    color='primary'
                                    onClick={() => onSendRecomandationRequest()}
                                >
                                    {language.homePage.analizeModal.send}
                                </Button>
                            </Stack>
                        </div>
                    </Box>
                </Modal>
            </Box>
            <div className={classes.footer}>
                <div className={classes.footerContainer}>
                    <Grid container spacing={1}>
                        <Grid xs={2} md={2} xl={2} />

                        <Grid xs={1} md={1} xl={1} >
                            <div className={classes.wikipedia} onClick={() => openInNewTab('https://ro.wikipedia.org/wiki/Pagina_principal%C4%83')}></div>
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            <TwitterIcon fontSize='large' className={classes.footerContainerIcon} />
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            {/* https://ro.pinterest.com/PredictOn/_saved/ */}
                            <PinterestIcon fontSize='large' className={classes.footerContainerIcon}  onClick={() => openInNewTab('https://ro.pinterest.com/PredictOn/_saved/')}/>
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            {/* https://www.instagram.com/bussinespredict/ */}
                            <InstagramIcon fontSize='large' className={classes.footerContainerIcon} onClick={() => openInNewTab('https://www.instagram.com/bussinespredict/')}/>
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            {/* https://www.youtube.com/channel/UCIpoFZhLzBycggzC8KmI56A */}
                            <YouTubeIcon fontSize='large' className={classes.footerContainerIcon} onClick={() => openInNewTab('https://www.youtube.com/channel/UCIpoFZhLzBycggzC8KmI56A')}/>
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            {/* https://www.facebook.com/profile.php?id=100082252195533 */}
                            <FacebookIcon fontSize='large' className={classes.footerContainerIcon} onClick={() => openInNewTab('https://www.facebook.com/profile.php?id=100082252195533')}/>
                        </Grid>
                        <Grid xs={1} md={1} xl={1} style={{ margin: "5px" }}>
                            {/* https://www.tiktok.com/@predicton8 */}
                            <div className={classes.tiktok} onClick={() => openInNewTab('https://www.tiktok.com/@predicton8')}></div>
                        </Grid>

                        <Grid xs={4} md={4} xl={4} />
                    </Grid>
                </div>
            </div>
        </>
    )
}
