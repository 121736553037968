import React, { useState, useEffect, useCallback } from 'react'
import {
	Button,
	TextField,
	Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import Payment from '../../../components/Payment'

const useStyles = makeStyles({
	container: {
		borderStyle: 'solid',
		borderColor: '#5840BB',
		borderWidth: '2em',
		textAlign: 'left',
		padding: '40px',
		background: 'white',
		height: '70vh'
	},
	saveUserBtn: {
		float: 'left'
	}
});

const questionarID = 'chestionar1'
const questionary = {
	description: 'Chestionar Calamnitati Naturale',
	amount: 1,
	currency: "EUR"
}

export default function PayPage() {
	const classes = useStyles()
	const [language, setLanguage] = useState(null)
	const [code, setCode] = useState(null)
	const [error, setError] = useState(null)
	const [userBody, setUserBody] = useState({
		firstName: '',
		lastName: '',
		email: '',
	})

	const [responseCreateUser, setResponsetReponseCreateUser] = useState(null)
	const [selectedTab, setSelectedTab] = useState(0)

	useEffect(() => {
		if (localStorage.getItem('language_JSON')) setLanguage(JSON.parse(localStorage.getItem('language_JSON')))
	}, [])

	useEffect(() => {
		if (!responseCreateUser) return
		if (responseCreateUser._id) {
			setSelectedTab(1)
		}
	}, [responseCreateUser])

	const onCreateUser = useCallback(() => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(userBody)
		}
		fetch(window.baseURL + '/users/', requestOptions)
			.then(response => response.json())
			.then(body => setResponsetReponseCreateUser(body))
	}, [userBody])

	const onBuyQuestionaire = useCallback(({ orderId, payerId }) => {
		if (!responseCreateUser?._id) return
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				questionaryId: questionarID,
				userId: responseCreateUser._id,
				orderId,
				payerId
			})
		}
		fetch(window.baseURL + '/questionary/buy', requestOptions)
			.then(response => response.json())
			.then(body => {
				if (body?.code) {
					localStorage.setItem('chestionar1', body.code)
					setCode(body.code)
					sendEmail(body.code)
				} else {
					setError(true)
				}
			})
	}, [responseCreateUser])

	const sendEmail = useCallback((code) => {
		let bodyHTML = ` <div>
                                <h3>
								${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b>${language.questionaryCalamnitatiPage.buyCodePage.afterPayment.questionary}</b>
                                </h3>
                                <br />
                                <span><b>${code}</b></span>
                            </div>`
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				userId: responseCreateUser?._id,
				subject: 'Confirmare plata chestionar',
				message: bodyHTML
			})
		}
		fetch(window.baseURL + '/email/send', requestOptions)
			.then(response => response.json())
	}, [responseCreateUser])

	if (!language) return

	return (
		<div className={classes.container}>
			{selectedTab === 0 ?
				(
					<div>
						<h1>{language.questionaryCalamnitatiPage.buyCodePage.title}</h1>
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='text'
							name='firstName'
							label={language.questionaryCalamnitatiPage.buyCodePage.firstName}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, firstName: e?.target?.value })}
						/>
						<br />
						<br />
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='text'
							name='lastName'
							label={language.questionaryCalamnitatiPage.buyCodePage.lastName}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, lastName: e?.target?.value })}
						/>
						<br />
						<br />
						<TextField
							style={{ maxWidth: 400 }}
							fullWidth
							type='email'
							name='email'
							label={language.questionaryCalamnitatiPage.buyCodePage.email}
							variant='outlined'
							onChange={(e) => setUserBody({ ...userBody, email: e?.target?.value })}
						/>
						<br />
						<br />

						<Button
							disabled={!userBody.firstName || !userBody.lastName || !userBody.email}
							className={classes.saveUserBtn}
							variant='contained'
							color='primary'
							onClick={() => onCreateUser()}
						>
							{language.questionaryCalamnitatiPage.buyCodePage.next}
						</Button>
					</div>
				) : null}
			{selectedTab === 1 ?
				(
					<div>
						<Payment questionary={questionary} onSuccess={onBuyQuestionaire} />
						{code ? (
							<div>
								<h3>
									{language.questionaryCalamnitatiPage.buyCodePage.afterPayment.title}
								</h3>
								<br />
								<br />
								<>
									<span> {language.questionaryCalamnitatiPage.buyCodePage.afterPayment.codeFor} <b> {language.questionaryCalamnitatiPage.buyCodePage.afterPayment.questionary}</b>: <b>{code}</b></span>
								</>
							</div>
						) : null}
						{error ? (
							<Alert severity="error">{language.questionaryCalamnitatiPage.buyCodePage.afterPayment.error}</Alert>
						) : null}
						<br />
						<br />
						{error || code ? (<Button
							variant='contained'
							color='primary'
							onClick={() => window.location.href = window.location.origin}
						>
							{language.questionaryCalamnitatiPage.buyCodePage.afterPayment.goToMenu}
						</Button>) : null}
					</div>
				) : null}
		</div>
	)
}
