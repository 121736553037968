import React, { useState } from 'react'
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Alert } from '@mui/material';

function Payment({ questionary, onSuccess, SuccessComponent }) {

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');


    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: questionary.description,
                        amount: {
                            currency_code: questionary.currency,
                            value: questionary.amount,
                        },
                    },
                ],
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => orderID);
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            onSuccess?.({ orderId: data.orderID, payerId: data.payerID });
            setErrorMessage('')
            setSuccess(true)
        });
    };

    const onError = (data, actions) => {
        setErrorMessage(`An Error occured: ${data.message}.\nPlease try again later`);
    };

    if (success)
        if (SuccessComponent)
            return <SuccessComponent />
        else
            return <h1>Mulțumim pentru plata efectuată!</h1>

    return (
        <>
            {
                errorMessage
                    ? (<> <Alert severity="error">{errorMessage}</Alert> <br /> </>)
                    : null
            }
            <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
            />
        </ >
    )
}

export default Payment

/*
    email: sb-nhkyo16090411@personal.example.com
    pass: !ZnC1h/Y
*/