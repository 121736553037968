import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import moment from 'moment'

const useStyles = makeStyles({
    container: {
        borderStyle: 'solid',
        borderColor: '#5840BB',
        borderWidth: '2em',
        textAlign: 'left',
        padding: '40px',
        background: 'white'
    },
    surveyContainer: {
        overflowX: 'auto',
        maxHeight: '70vh',
        background: 'white'
    }
});

export default function Statistics() {
    const classes = useStyles()

    const [data, setData] = useState(null)

    useEffect(() => {
        getAnalyticsData()
    }, [])

    const getAnalyticsData = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ password: "UgdMVRyPaQ87" })
        }
        fetch(window.baseURL + '/analytics', requestOptions)
            .then(response => response.json())
            .then(d => setData(d))
    }

    return (
        <div className={classes.container}>
            <div className={classes.surveyContainer}>
                {data ? (
                    <>
                        <h1>Statistici</h1>
                        <hr />
                        <br />
                        <h3>Utilizatori: {data.users.total}</h3>
                        <h3>Chestionare generate: {data.questionaries.total}</h3>
                        <h3>Chestionare completate: {data.questionaries.completedCount}</h3>
                        <h3>CALAMNITĂȚI NATURALE: {data.questionaries.countByType.chestionar1}</h3>
                        <h3>RISC ÎN SITUAȚIE DE RĂZBOI: {data.questionaries.countByType.chestionar2}</h3>
                        <h3>PANDEMIE: {data.questionaries.countByType.chestionar3}</h3>
                        <h3>CRIZĂ FINANCIAR: {data.questionaries.countByType.chestionar4}</h3>
                        <h3>Ultimul completat in date de: {moment(data.questionaries.lastCompleted).format('DD-MM-YYYY, HH:ss')}</h3>
                    </>
                ) : null}
            </div>
        </div>
    )
}